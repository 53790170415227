import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Link as ChakraLink,
  Divider,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  VStack,
  Card,
  CardBody,
  CardHeader,
  Badge,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from '../axiosConfig';
import { Link } from 'react-router-dom';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [stats, setStats] = useState({
    organizations: 0,
    users: 0,
    surveyResponses: 0,
    averageResponseTime: 0,
  });
  const [organizations, setOrganizations] = useState([]);
  const [chartData, setChartData] = useState(null);

  const chartBg = useColorModeValue('white', 'gray.800');
  const isMobile = useBreakpointValue({ base: true, md: false }); // Moved to the top level

  const colors = [
    'rgba(255, 99, 132, 1)', // Red
    'rgba(54, 162, 235, 1)', // Blue
    'rgba(255, 206, 86, 1)', // Yellow
    'rgba(75, 192, 192, 1)', // Turquoise
    'rgba(153, 102, 255, 1)', // Purple
    'rgba(255, 159, 64, 1)', // Orange
    'rgba(99, 255, 132, 1)', // Lime
    'rgba(235, 54, 162, 1)', // Pink
    'rgba(86, 255, 206, 1)', // Ocean green
    'rgba(64, 159, 255, 1)', // Light Blue
  ];

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const [orgRes, userRes, responseRes, avgTimeRes] = await Promise.all([
          axios.get('/stats/organizations'),
          axios.get('/stats/users'),
          axios.get('/stats/survey_responses'),
          axios.get('/stats/average_response_time'),
        ]);

        setStats({
          organizations: orgRes.data.total,
          users: userRes.data.total,
          surveyResponses: responseRes.data.total,
          averageResponseTime: avgTimeRes.data.average_time,
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  useEffect(() => {
    const fetchOrganizationsWithSurveys = async () => {
      try {
        const response = await axios.get('/stats/organizations_with_surveys');
        setOrganizations(response.data);

        const labels = response.data.map((org) => org.organization_name);
        const datasets = [];

        response.data.forEach((org) => {
          org.surveys.forEach((survey) => {
            const existingDataset = datasets.find((ds) => ds.label === survey.survey_name);
            if (!existingDataset) {
              const colorIndex = datasets.length % colors.length;
              datasets.push({
                label: survey.survey_name,
                data: new Array(labels.length).fill(0),
                borderColor: colors[colorIndex],
                backgroundColor: colors[colorIndex].replace('1)', '0.2)'),
                tension: 0.3,
                fill: true,
              });
            }
          });
        });

        response.data.forEach((org, orgIndex) => {
          org.surveys.forEach((survey) => {
            const dataset = datasets.find((ds) => ds.label === survey.survey_name);
            if (dataset) {
              dataset.data[orgIndex] = survey.respondent_count;
            }
          });
        });

        setChartData({
          labels,
          datasets,
        });
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };

    fetchOrganizationsWithSurveys();
  }, []);

  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Responses',
          },
        },
        x: {
          title: {
            display: true,
            text: '',
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          callbacks: {
            footer: (tooltipItems) => {
              const orgIndex = tooltipItems[0].dataIndex;
              const total = tooltipItems.reduce((sum, item) => sum + item.raw, 0);
              return `Total Responses for ${chartData.labels[orgIndex]}: ${total}`;
            },
          },
        },
      },
    }),
    [chartData]
  );

  return (
    <Box p={6}>
      <Heading mb={6}>Dashboard</Heading>

      {/* Summary Metrics */}
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6} mb={6}>
        <Stat bg="green.50" borderRadius="md" p={4} shadow="md">
          <StatLabel>Total Organizations</StatLabel>
          <StatNumber fontSize="4xl">{stats.organizations}</StatNumber>
        </Stat>
        <Stat bg="blue.50" borderRadius="md" p={4} shadow="md">
          <StatLabel>Total Users</StatLabel>
          <StatNumber fontSize="4xl">{stats.users}</StatNumber>
        </Stat>
        <Stat bg="purple.50" borderRadius="md" p={4} shadow="md">
          <StatLabel>Respondents</StatLabel>
          <StatNumber fontSize="4xl">{stats.surveyResponses}</StatNumber>
        </Stat>
        <Stat bg="yellow.50" borderRadius="md" p={4} shadow="md">
          <StatLabel>Average Response Time (minutes)</StatLabel>
          <StatNumber fontSize="4xl">{(stats.averageResponseTime / 60).toFixed(2)}</StatNumber>
        </Stat>
      </SimpleGrid>

      <Divider />

      {/* Chart */}
      <Box
        mt={6}
        mb={6}
        bg={chartBg}
        p={4}
        shadow="md"
        borderRadius="md"
        height={{ base: '300px', md: '500px' }}
        maxHeight={{ base: '400px' }}
      >
        <Heading size="md" mb={4}>
          Survey Responses Overview by Organization and Survey
        </Heading>
        {chartData ? (
          <Box height="100%" width="100%">
            <Line data={chartData} options={chartOptions} />
          </Box>
        ) : (
          <Text>Loading chart...</Text>
        )}
      </Box>

      <Divider />

      {/* Organizations and Surveys Section */}
      <Box mt={6}>
        <Heading size="md" mb={4}>
          Organizations and Their Surveys
        </Heading>
        {isMobile ? (
          <VStack spacing={4} align="stretch">
            {organizations.map((org) => (
              <Card key={org.id} borderWidth="1px" shadow="sm" borderRadius="md">
                <CardHeader>
                  <ChakraLink
                    as={Link}
                    to={`/app/organizations/${org.id}`}
                    color="teal.500"
                    fontWeight="bold"
                    fontSize="lg"
                  >
                    {org.organization_name}
                  </ChakraLink>
                </CardHeader>
                <CardBody>
                  {org.surveys.map((survey) => (
                    <Box key={survey.survey_name} mb={4}>
                      <Text mr={2}>
                        {survey.survey_name}
                      </Text>
                      <Text display="inline" fontWeight="bold">
                        Responses: {survey.respondent_count}
                      </Text>
                    </Box>
                  ))}
                </CardBody>
              </Card>
            ))}
          </VStack>
        ) : (
          <Box overflowX="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Organization</Th>
                  <Th>Survey</Th>
                  <Th isNumeric>Responses</Th>
                </Tr>
              </Thead>
              <Tbody>
                {organizations.map((org) =>
                  org.surveys.map((survey, idx) => (
                    <Tr key={`${org.organization_name}-${survey.survey_name}`}>
                      {idx === 0 && (
                        <Td rowSpan={org.surveys.length} fontWeight="bold" verticalAlign="top">
                          <ChakraLink as={Link} to={`/app/organizations/${org.id}`} color="teal.500" fontWeight="bold">
                            {org.organization_name}
                          </ChakraLink>
                        </Td>
                      )}
                      <Td>{survey.survey_name}</Td>
                      <Td isNumeric>{survey.respondent_count}</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Box>
        )}
        {organizations.length === 0 && (
          <Text mt={4} textAlign="center">
            No organizations or surveys available.
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;