import React from 'react';
import { Box, Button, Heading, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const DevToolsDashboard = () => {
  const { user } = useUser();

  // Restrict access to tech users only
  if (!user || user.role !== 'tech') {
    return <Box p={6}><Heading size="md">Access Denied</Heading></Box>;
  }

  return (
    <Box p={6} mx="auto">
      <Heading as="h2" size="lg" mb={6}>Developer Tools</Heading>
      <VStack spacing={4} align="stretch">
        <Button as={Link} to="/app/devtools/feature-toggles" w={250} colorScheme="blue">
          Manage Feature Toggles
        </Button>
      </VStack>
    </Box>
  );
};

export default DevToolsDashboard;
