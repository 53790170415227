import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Text,
  Icon,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  VStack,
  HStack,
  Badge,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaEnvelope, FaEnvelopeOpen, FaEye } from 'react-icons/fa';
import axios from '../axiosConfig';

const MessagesList = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue('gray.100', 'gray.700');

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('/messages');
        setMessages(
          response.data.map((message) => ({
            ...message,
            read: message.status === 'read',
          }))
        );
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  const openMessage = (message) => {
    setSelectedMessage(message);
    onOpen();
    if (!message.read) {
      markAsRead(message.id);
    }
  };

  const markAsRead = async (messageId) => {
    try {
      await axios.patch(`/messages/${messageId}`, { status: 'read' });
      setMessages((prev) =>
        prev.map((msg) => (msg.id === messageId ? { ...msg, read: true } : msg))
      );
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const renderMessageCard = (message) => (
    <Box
      key={message.id}
      p={4}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      shadow="md"
      mb={4}
      bg={bgColor}
    >
      <VStack align="start" spacing={3}>
        <HStack spacing={2}>
          <Icon
            as={message.read ? FaEnvelopeOpen : FaEnvelope}
            boxSize={6}
            color={message.read ? 'gray.500' : 'blue.500'}
          />
          <Text fontWeight={message.read ? 'normal' : 'bold'}>
            {message.name}
          </Text>
        </HStack>
        <Text>Email: {message.email}</Text>
        <Text noOfLines={3}>Message: {message.content}</Text> {/* Limit to 3 lines */}
        <HStack>
          <IconButton
            icon={<FaEye />}
            aria-label="View Message"
            variant="outline"
            title="View Message"
            colorScheme="cyan"
            size="sm"
            onClick={() => openMessage(message)}
          />
        </HStack>
      </VStack>
    </Box>
  );

  const renderMessageTable = () => (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Read/Unread</Th>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Message</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {messages.map((message) => (
            <Tr key={message.id} bg={bgColor}>
              <Td>
                <Icon
                  as={message.read ? FaEnvelopeOpen : FaEnvelope}
                  boxSize={6}
                  color={message.read ? 'gray.500' : 'blue.500'}
                />
              </Td>
              <Td>
                <Text fontWeight={message.read ? 'normal' : 'bold'}>
                  {message.name}
                </Text>
              </Td>
              <Td>{message.email}</Td>
              <Td maxW="200px">
                <Text isTruncated>{message.content}</Text> {/* Limit to 2 lines */}
              </Td>
              <Td>
                <IconButton
                  icon={<FaEye />}
                  aria-label="View Message"
                  variant="outline"
                  title="View Message"
                  colorScheme="cyan"
                  size="sm"
                  onClick={() => openMessage(message)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <Heading as="h1" size="lg" mb={6}>
        Messages
      </Heading>

      {messages.length > 0 ? (
        isMobile ? (
          <Box>{messages.map(renderMessageCard)}</Box>
        ) : (
          renderMessageTable()
        )
      ) : (
        <Text mt={6} textAlign="center">
          No messages found.
        </Text>
      )}

      {/* Message Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedMessage?.name}<br />[{selectedMessage?.email}]
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontSize="md" whiteSpace="pre-wrap">
                {selectedMessage?.content}
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MessagesList;