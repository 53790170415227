import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

const OrgResults = () => {
  return (
    <Box p={6} maxW="container.md" mx="auto" textAlign="center">
      <VStack spacing={4}>
        <Heading as="h2" size="lg">
          Survey Results Overview
        </Heading>
        <Text fontSize="md" color="gray.600">
          This page will provide insights into your organization's survey results. 
          You will be able to analyze response trends, view participation rates, 
          and explore data-driven insights to help improve decision-making.
        </Text>
        <Text fontSize="md" color="gray.500">
          Future updates will include interactive charts, detailed response breakdowns, 
          and export options for further analysis.
        </Text>
      </VStack>
    </Box>
  );
};

export default OrgResults;