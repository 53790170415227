import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Spacer,
  Link as ChakraLink,
  useBreakpointValue,
  VStack,
  Text,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { Link } from 'react-router-dom';

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);
  const isMobile = useBreakpointValue({ base: true, md: false }); // Check if the screen size is mobile

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axios.get('/organizations');
        setOrganizations(response.data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };
    fetchOrganizations();
  }, []);

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>
        Organizations
      </Heading>
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Spacer />
        <Button
          as={Link}
          to="/app/organizations/create"
          mt={4}
          colorScheme="green"
          variant="outline"
          size="sm"
        >
          Add Organization
        </Button>
      </Flex>

      {isMobile ? (
        // Mobile View: Card-style layout
        <VStack spacing={4} mt={4}>
          {organizations.map((org) => (
            <Box
              key={org.id}
              borderWidth="1px"
              borderRadius="md"
              p={4}
              shadow="sm"
              w="100%"
              bg="white"
            >
              <ChakraLink
                as={Link}
                to={`/app/organizations/${org.id}`}
                color="teal.500"
                fontWeight="bold"
                fontSize="lg"
              >
                {org.name}
              </ChakraLink>
              <Text fontSize="sm" color="gray.500">
                <strong>Contact:</strong> {org.contact || 'N/A'}
              </Text>
              <Text fontSize="sm" color="gray.500">
                <strong>Email:</strong> {org.email || 'N/A'}
              </Text>
              <Text fontSize="sm" color="gray.500">
                <strong>Phone:</strong> {org.phone || 'N/A'}
              </Text>
              {org.has_user ? (
                <Badge colorScheme="blue" mt={2}>User Assigned</Badge>
              ) : (
                <Badge>No User</Badge>
              )}
            </Box>
          ))}
        </VStack>
      ) : (
        // Desktop View: Table layout
        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Contact Name</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
              <Th>User assigned</Th>
            </Tr>
          </Thead>
          <Tbody>
            {organizations.map((org) => (
              <Tr key={org.id}>
                <Td>
                  <ChakraLink
                    as={Link}
                    to={`/app/organizations/${org.id}`}
                    color="teal.500"
                    fontWeight="bold"
                  >
                    {org.name}
                  </ChakraLink>
                </Td>
                <Td>{org.contact}</Td>
                <Td>{org.email}</Td>
                <Td>{org.phone}</Td>
                <Td>
                  {org.has_user ? (
                    <Badge colorScheme="blue">Yes</Badge>
                  ) : (
                    <Badge>No</Badge>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default OrganizationList;