import { useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Image,
  InputLeftElement,
  chakra,
  Box,
  Link,
  FormControl,
  FormHelperText,
  InputRightElement,
  useToast,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import Cookies from "js-cookie";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import axios from "../../axiosConfig";
import { useTranslation } from "react-i18next";
import { useUser } from "../../contexts/UserContext";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser, updateSettings } = useUser();
  const navigate = useNavigate();
  const toast = useToast();
  const { setColorMode } = useColorMode();
  const [showPassword, setShowPassword] = useState(false);
  const logo = useColorModeValue("logo-md.png", "logo-white-md.png");

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/login", { user: { email, password } });
      if (response.status === 200) {
        const token = response.data.token;
        Cookies.set("hkeAuthToken", token, {
          secure: false,
          sameSite: "Lax",
          path: "/",
        });
  
        // Set token creation time for expiration checks
        Cookies.set("tokenCreationTime", Date.now().toString(), { secure: false, sameSite: "Lax", path: "/" });
  
        // Retrieve user data and settings after successful login
        const userResponse = await axios.get("/users/me");
        if (userResponse.status === 200) {
          const userData = userResponse.data;
          setUser(userData);
  
          // Update theme and language immediately
          if (userData.settings?.language) {
            i18n.changeLanguage(userData.settings.language);
          }
          if (userData.settings?.theme) {
            setColorMode(userData.settings.theme);
            updateSettings({ theme: userData.settings.theme, language: userData.settings.language });
          }
  
          // Successful login feedback
          toast({
            title: t("login.login_success"),
            status: "success",
            duration: 3000,
            isClosable: true,
          });
  
          // 🔹 Redirect based on role
          if (userData.role === "org_user") {
            navigate("/hub/dashboard"); // Redirect org users to Hub
          } else {
            navigate("/app/dashboard"); // Redirect all others to the main app
          }
        }
      }
    } catch (error) {
      console.log("Login error:", error);
  
      // Handle specific error messages
      const errorMessage = error.response?.data?.error || t("login.login_error");
  
      toast({
        title: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const bgColor = useColorModeValue("gray.100", "gray.800");
  const formBgColor = useColorModeValue("white", "gray.700");
  const inputBgColor = useColorModeValue("gray.50", "gray.600");
  const buttonColorScheme = useColorModeValue("gray", "teal");

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor={bgColor}
      justifyContent="center"
      alignItems="center"
    >
      <Stack flexDir="column" mb="2" justifyContent="center" alignItems="center">
        <Image src={`/images/${logo}`} alt="HKE Learning" mx="auto" />
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleLogin}>
            <Stack spacing={4} p="1rem" backgroundColor={formBgColor} boxShadow="md">
              <FormControl>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <CFaUserAlt color="gray.300" />
                  </InputLeftElement>
                  <Input
                    type="email"
                    placeholder={t("login.email_placeholder")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    backgroundColor={inputBgColor}
                  />
                </InputGroup>
              </FormControl>
              <FormControl>
                <InputGroup>
                  <InputLeftElement color="gray.300">
                    <CFaLock color="gray.300" />
                  </InputLeftElement>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder={t("login.password_placeholder")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    backgroundColor={inputBgColor}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? t("login.hide_password") : t("login.show_password")}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText textAlign="right">
                  <Link as={RouterLink} to="/password/forgot">
                    {t("login.forgot_password")}
                  </Link>
                </FormHelperText>
              </FormControl>
              <Button borderRadius={0} type="submit" variant="outline" colorScheme={buttonColorScheme} width="full">
                {t("login.login_button")}
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LoginPage;