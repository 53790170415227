import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, Heading, useToast } from '@chakra-ui/react';
import { useUser } from '../../contexts/UserContext';

const Profile = ({ redirectTo }) => {
  const { user, updateUser } = useUser();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const toast = useToast();

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = await updateUser({ first_name: firstName, last_name: lastName, email });

    if (success) {
      toast({
        title: "Profile updated.",
        description: "Your profile information has been updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      if (redirectTo) {
        window.location.href = redirectTo; // Redirect after update
      }
    } else {
      toast({
        title: "Error updating profile",
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="lg" mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Edit Profile</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              disabled
            />
          </FormControl>

          <Button type="submit" colorScheme="green" size="lg" width="full" variant="outline">
            Save Changes
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default Profile;