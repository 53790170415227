import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  IconButton,
  VStack,
  HStack,
  Badge,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FaTrash, FaPencilAlt, FaRegFileAlt } from 'react-icons/fa';

const SurveyList = ({ surveys, handleDelete }) => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const renderSurveyCard = (survey) => (
    <Box
      key={survey.id}
      p={4}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      shadow="md"
      w="100%"
      bg="white"
      mb={4}
    >
      <VStack align="start" spacing={2}>
        <Heading size="sm">{survey.name}</Heading>
        <HStack>
          <Badge colorScheme="blue">Pages: {survey.pages_count}</Badge>
          <Badge colorScheme="purple">Questions: {survey.questions_count}</Badge>
          <Badge colorScheme="green">Lan.: {survey.language}</Badge>
        </HStack>
        <HStack mt={2} spacing={2}>
          <Button
            leftIcon={<FaRegFileAlt />}
            colorScheme="cyan"
            variant="outline"
            size="sm"
            onClick={() => navigate(`/app/surveys/${survey.id}/pages`)}
          >
            Pages
          </Button>
          <Button
            as={Link}
            to={`/app/surveys/edit/${survey.id}`}
            leftIcon={<FaPencilAlt />}
            colorScheme="yellow"
            variant="outline"
            size="sm"
          >
            Edit
          </Button>
          <Button
            leftIcon={<FaTrash />}
            colorScheme="red"
            variant="outline"
            size="sm"
            onClick={() => handleDelete(survey.id)}
          >
            Delete
          </Button>
        </HStack>
      </VStack>
    </Box>
  );

  const renderSurveyTable = () => (
    <TableContainer mt={6}>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Pages</Th>
            <Th>Questions</Th>
            <Th>Language</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {surveys.map((survey) => (
            <Tr key={survey.id}>
              <Td>{survey.name}</Td>
              <Td>{survey.pages_count}</Td>
              <Td>{survey.questions_count}</Td>
              <Td>{survey.language}</Td>
              <Td>
                <IconButton
                  icon={<FaRegFileAlt />}
                  aria-label="Manage Pages"
                  title="Manage Pages"
                  variant="outline"
                  colorScheme="cyan"
                  size="sm"
                  onClick={() => navigate(`/app/surveys/${survey.id}/pages`)}
                  mr={2}
                />
                <IconButton
                  as={Link}
                  to={`/app/surveys/edit/${survey.id}`}
                  icon={<FaPencilAlt />}
                  aria-label="Edit Survey"
                  title="Edit Survey"
                  variant="outline"
                  size="sm"
                  colorScheme="yellow"
                  mr={2}
                />
                <IconButton
                  icon={<FaTrash />}
                  aria-label="Delete Survey"
                  title="Delete Survey"
                  variant="outline"
                  size="sm"
                  colorScheme="red"
                  onClick={() => handleDelete(survey.id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>
        Surveys
      </Heading>
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Spacer />
        <Button
          as={Link}
          to="/app/surveys/create"
          mt={4}
          colorScheme="green"
          variant="outline"
          size="sm"
        >
          Add Survey
        </Button>
      </Flex>

      {surveys.length > 0 ? (
        isMobile ? (
          <Box mt={6}>{surveys.map(renderSurveyCard)}</Box>
        ) : (
          renderSurveyTable()
        )
      ) : (
        <Text mt={6} textAlign="center">
          No surveys found.
        </Text>
      )}
    </Box>
  );
};

export default SurveyList;