import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  VStack,
  useToast,
  Heading,
} from '@chakra-ui/react';
import axios from '../../../axiosConfig';

const FeatureToggleForm = () => {
  const { id } = useParams(); // Get ID from URL params (edit mode)
  const navigate = useNavigate();
  const toast = useToast();

  const [formValues, setFormValues] = useState({
    key: '',
    active: false,
    value: '',
    activated_at: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (id) {
      // Fetch toggle data when editing
      const fetchFeatureToggle = async () => {
        try {
          const response = await axios.get(`/feature_toggles/${id}`);
          
          // Convert `activated_at` to `YYYY-MM-DD` format for <input type="date">
          const formattedDate = response.data.activated_at
            ? new Date(response.data.activated_at).toISOString().split('T')[0]
            : '';

          setFormValues({
            key: response.data.key || '',
            active: response.data.active || false,
            value: response.data.value || '',
            activated_at: formattedDate,
          });
        } catch (error) {
          console.error('Error fetching feature toggle:', error);
          toast({
            title: 'Error',
            description: 'Failed to load feature toggle data.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      };

      fetchFeatureToggle();
    }
  }, [id, toast]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value || '',
    }));
  };

  const handleSwitchChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      active: event.target.checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      if (id) {
        await axios.put(`/feature_toggles/${id}`, { feature_toggle: formValues });
        toast({
          title: 'Success',
          description: 'Feature toggle updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        await axios.post('/feature_toggles', { feature_toggle: formValues });
        toast({
          title: 'Success',
          description: 'Feature toggle created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      navigate('/app/devtools/feature-toggles');
    } catch (error) {
      console.error('Error saving feature toggle:', error);
      toast({
        title: 'Error',
        description: 'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box p={6} mx="auto">
      <Heading as="h2" size="lg" mb={6}>
        {id ? 'Edit Feature Toggle' : 'Create Feature Toggle'}
      </Heading>
      <Box as="form" onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>Key</FormLabel>
            <Input
              type="text"
              name="key"
              value={formValues.key}
              onChange={handleInputChange}
              placeholder="Enter feature toggle key"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Active</FormLabel>
            <Switch
              name="active"
              isChecked={formValues.active}
              onChange={handleSwitchChange}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Value</FormLabel>
            <Input
              type="text"
              name="value"
              value={formValues.value}
              onChange={handleInputChange}
              placeholder="Optional: enter a value"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Activation Date</FormLabel>
            <Input
              type="date"
              name="activated_at"
              value={formValues.activated_at}
              onChange={handleInputChange}
            />
          </FormControl>

          <Flex justifyContent="flex-end">
            <Button type="submit" colorScheme="green" variant="outline" isLoading={isSubmitting}>
              {id ? 'Update Toggle' : 'Create Toggle'}
            </Button>
          </Flex>
        </VStack>
      </Box>
    </Box>
  );
};

export default FeatureToggleForm;