import React from 'react';
import { Box, VStack, Text, Icon, Link as ChakraLink, useColorModeValue, useColorMode } from '@chakra-ui/react';
import { FaTachometerAlt, FaClipboardList, FaChartPie, FaCogs } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const OrgSidebar = () => {
  const { t } = useTranslation();

  // Dynamically get colors based on the theme (dark or light)
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.100');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
  const { colorMode } = useColorMode();
  const logo = colorMode === 'dark' ? 'logo-white-md.png' : 'logo-hub-md.png';

  return (
    <Box
      as="aside"
      width="250px"
      bg={bgColor}
      color={textColor}
      height="100vh"
      p={4}
      position="fixed"
      top="0"
      left="0"
    >
      {/* Company logo at the top */}
      <Box mb={8} textAlign="center">
        <img src={`/images/${logo}`} alt="HKE Learning" style={{ width: '250px' }} />
      </Box>

      <VStack align="start" spacing={4}>
        <ChakraLink 
          href="/hub/dashboard" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaTachometerAlt} mr={3} />
            <Text>{t('sidebar.dashboard')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/hub/surveys" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaClipboardList} mr={3} />
            <Text>{t('sidebar.surveys')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/hub/results" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaChartPie} mr={3} />
            <Text>{t('sidebar.results')}</Text>
          </Box>
        </ChakraLink>

        <ChakraLink 
          href="/hub/settings" 
          _hover={{ textDecoration: 'none', backgroundColor: hoverBgColor }} 
          fontWeight="bold" 
          p={3} 
          borderRadius="md"
          width="100%"
        >
          <Box display="flex" alignItems="center">
            <Icon as={FaCogs} mr={3} />
            <Text>{t('sidebar.settings')}</Text>
          </Box>
        </ChakraLink>
      </VStack>
    </Box>
  );
};

export default OrgSidebar;