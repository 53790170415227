import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Stack,
  Heading,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const ChangePassword = () => {
  const { changePassword } = useUser();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  // Determine if the user is in the Hub or App area
  const isHubUser = location.pathname.startsWith('/hub');

  const handleShowClick = () => setShowPassword(!showPassword);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast({
        title: 'Passwords do not match',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const success = await changePassword(currentPassword, newPassword);
      if (success) {
        toast({
          title: 'Password changed successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Redirect based on area
        navigate(`/${isHubUser ? 'hub' : 'app'}/profile`);
      }
    } catch (error) {
      console.log('Failing to change password with error..: ', error)
      toast({
        title: 'Failed to change password',
        description: error.response?.data?.message || 'Please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="md" mx="auto" mt={8}>
      <Stack spacing={4}>
        <Heading as="h2" size="lg">
          Change Password
        </Heading>
        <Text textAlign="center" fontSize="sm" color="gray.500">
          Please enter your current and new password to update your account.
        </Text>
        
        <form onSubmit={handleChangePassword}>
          <FormControl id="currentPassword" isRequired>
            <FormLabel>Current Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                  {showPassword ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl id="newPassword" mt={4} isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>

          <FormControl id="confirmPassword" mt={4} isRequired>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="green"
            width="full"
            mt={6}
            variant="outline"
          >
            Update Password
          </Button>
        </form>
      </Stack>
    </Box>
  );
};

export default ChangePassword;