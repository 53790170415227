import { useState } from "react";
import {
  Flex,
  Input,
  Button,
  Stack,
  Box,
  FormControl,
  FormHelperText,
  useToast,
  useColorModeValue,
  Text,
  Heading,
} from "@chakra-ui/react";
import axios from "../../axiosConfig";
import { useTranslation } from "react-i18next";
import Header from "../../site/components/Header";
import SlimFooter from "../../site/components/SlimFooter";

const ResetPasswordRequestPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const formBgColor = useColorModeValue("white", "gray.800");
  const buttonColorScheme = useColorModeValue("teal", "orange");
  const textColor = useColorModeValue("gray.700", "gray.200");

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post("/passwords/forgot", { email });
      const messageKey = response.data.message;
      toast({
        title: t(messageKey),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Forgot password error:", error);
      toast({
        title: t("reset_password_request.error_title"),
        description: t("reset_password_request.error_description"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex
      direction="column"
      minHeight="100vh"
      backgroundColor={bgColor}
    >
      {/* Header */}
      <Header />

      {/* Main Content */}
      <Flex
        flex="1"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        {/* Title and Message */}
        <Box mb={8}>
          <Heading as="h1" size="lg" color={textColor} mb={2}>
            {t("reset_password_request.page_title")}
          </Heading>
          <Text fontSize="md" color={textColor}>
            {t("reset_password_request.page_description")}
          </Text>
        </Box>

        <Stack flexDir="column" justifyContent="center" alignItems="center">
          <Box minW={{ base: "90%", md: "468px" }}>
            <form onSubmit={handleRequestReset}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor={formBgColor}
                boxShadow="md"
                borderRadius="8px"
              >
                <FormControl>
                  <Input
                    type="email"
                    placeholder={t("reset_password_request.email_placeholder")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <FormHelperText>
                    {t("reset_password_request.email_helper")}
                  </FormHelperText>
                </FormControl>
                <Button
                  borderRadius="4px"
                  type="submit"
                  colorScheme={buttonColorScheme}
                  width="full"
                  isLoading={isSubmitting}
                >
                  {t("reset_password_request.button_text")}
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>

      {/* Footer */}
      <SlimFooter />
    </Flex>
  );
};

export default ResetPasswordRequestPage;