import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Switch,
  IconButton,
  useToast,
  Flex,
  Spacer,
  VStack,
  Text,
  useBreakpointValue,
  HStack,
} from '@chakra-ui/react';
import { FaTrash, FaPlus, FaPencilAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from '../../../axiosConfig';
import { useUser } from '../../../contexts/UserContext';

const FeatureToggles = () => {
  const { user } = useUser();
  const toast = useToast();
  const [toggles, setToggles] = useState([]);
  const isMobile = useBreakpointValue({ base: true, md: false }); // Detect mobile screens

  useEffect(() => {
    const fetchToggles = async () => {
      try {
        const response = await axios.get('/feature_toggles');
        setToggles(response.data);
      } catch (error) {
        console.error('Error fetching feature toggles:', error);
        toast({
          title: 'Error',
          description: 'Failed to load feature toggles.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchToggles();
  }, [toast]);

  const toggleFeature = async (id, active) => {
    try {
      await axios.put(`/feature_toggles/${id}`, { feature_toggle: { active } });
      setToggles(toggles.map(toggle => toggle.id === id ? { ...toggle, active } : toggle));
    } catch (error) {
      console.error('Error updating toggle:', error);
      toast({
        title: 'Error',
        description: 'Failed to update feature toggle.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteToggle = async (id) => {
    try {
      await axios.delete(`/feature_toggles/${id}`);
      setToggles(toggles.filter(toggle => toggle.id !== id));
      toast({
        title: 'Success',
        description: 'Feature toggle deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting toggle:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete feature toggle.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Not Scheduled';
    return new Date(dateString).toLocaleDateString();
  };


  if (!user || user.role !== 'tech') {
    return <Box p={6}><Heading size="md">Access Denied</Heading></Box>;
  }

  return (
    <Box p={6} mx="auto">
      <Flex mb={4}>
        <Heading as="h2" size="lg">Feature Toggles</Heading>
        <Spacer />
        <Button as={Link} to="/app/devtools/feature-toggles/create" colorScheme="green" variant="outline" leftIcon={<FaPlus />}>
          New Toggle
        </Button>
      </Flex>

      {isMobile ? (
        <VStack spacing={4}>
          {toggles.length > 0 ? (
            toggles.map((toggle) => (
              <Box
                key={toggle.id}
                p={4}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                shadow="md"
                w="100%"
              >
                <Heading size="sm">{toggle.key}</Heading>
                <Text fontSize="sm" mt={1}>
                  Activation Date: {toggle.activated_at ? formatDate(toggle.activated_at) : 'Not Scheduled'}
                </Text>

                <HStack mt={3} justify="space-between">
                  <Switch
                    isChecked={toggle.active}
                    onChange={(e) => toggleFeature(toggle.id, e.target.checked)}
                  />
                  <HStack>
                    <IconButton
                      as={Link}
                      to={`/app/devtools/feature-toggles/edit/${toggle.id}`}
                      icon={<FaPencilAlt />}
                      aria-label="Edit Toggle"
                      title="Edit Toggle"
                      colorScheme="yellow"
                      size="sm"
                    />
                    <IconButton
                      icon={<FaTrash />}
                      aria-label="Delete Toggle"
                      title="Delete Toggle"
                      colorScheme="red"
                      size="sm"
                      onClick={() => deleteToggle(toggle.id)}
                    />
                  </HStack>
                </HStack>
              </Box>
            ))
          ) : (
            <Text>No feature toggles found.</Text>
          )}
        </VStack>
      ) : (
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Key</Th>
                <Th>Status</Th>
                <Th>Activation Date</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {toggles.length > 0 ? (
                toggles.map((toggle) => (
                  <Tr key={toggle.id}>
                    <Td>{toggle.key}</Td>
                    <Td>
                      <Switch
                        isChecked={toggle.active}
                        onChange={(e) => toggleFeature(toggle.id, e.target.checked)}
                      />
                    </Td>
                    <Td>{toggle.activated_at ? formatDate(toggle.activated_at) : 'Not Scheduled'}</Td>
                    <Td>
                      <IconButton
                        as={Link}
                        to={`/app/devtools/feature-toggles/edit/${toggle.id}`}
                        icon={<FaPencilAlt />}
                        aria-label="Edit Toggle"
                        title="Edit Toggle"
                        colorScheme="yellow"
                        size="sm"
                        mr={2}
                      />
                      <IconButton
                        icon={<FaTrash />}
                        aria-label="Delete Toggle"
                        title="Delete Toggle"
                        colorScheme="red"
                        size="sm"
                        onClick={() => deleteToggle(toggle.id)}
                      />
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={4}>No feature toggles found.</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default FeatureToggles;