import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  IconButton,
  Flex,
  Heading,
  Text,
  Spacer,
  Box,
  VStack,
  HStack,
  Badge,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import axios from '../../axiosConfig';

// Map server roles to user-friendly display names
const roleMap = {
  tech: 'Tech',
  admin: 'Admin',
  user: 'User',
  org_user: 'Organization User',
};

const UserList = () => {
  const [users, setUsers] = useState([]);
  const { user } = useUser();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    if (id === user?.id) {
      alert('You cannot delete yourself!');
      return;
    }
    try {
      await axios.delete(`/users/${id}`);
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const canEditOrDelete = (theUser) => {
    if (!user || (user.role !== 'tech' && user.role !== 'admin')) {
      return false;
    }
    return theUser.id !== user.id; // Prevent self-edit or self-delete
  };

  const renderUserCard = (user) => (
    <Box
      key={user.id}
      p={4}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      shadow="md"
      mb={4}
    >
      <VStack align="start" spacing={2}>
        <Heading size="sm">{`${user.first_name} ${user.last_name}`}</Heading>
        <Text>Email: {user.email}</Text>
        <HStack>
          <Badge colorScheme={user.role === 'tech' ? 'purple' : 'blue'}>
            {roleMap[user.role] || 'Unknown Role'}
          </Badge>
        </HStack>

        {canEditOrDelete(user) && (
          <HStack mt={2}>
            <Button
              as={Link}
              to={`/app/users/edit/${user.id}`}
              leftIcon={<FaPencilAlt />}
              size="sm"
              variant="outline"
              colorScheme="yellow"
            >
              Edit
            </Button>
            <Button
              leftIcon={<FaTrash />}
              size="sm"
              colorScheme="red"
              variant="outline"
              onClick={() => handleDelete(user.id)}
            >
              Delete
            </Button>
          </HStack>
        )}
      </VStack>
    </Box>
  );

  const renderUserTable = () => (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Role</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <Tr key={user.id}>
                <Td>{`${user.first_name} ${user.last_name}`}</Td>
                <Td>{user.email}</Td>
                <Td>{roleMap[user.role] || 'Unknown Role'}</Td>
                <Td>
                  {canEditOrDelete(user) && (
                    <>
                      <IconButton
                        as={Link}
                        to={`/app/users/edit/${user.id}`}
                        icon={<FaPencilAlt />}
                        aria-label="Edit User"
                        title="Edit User"
                        variant="outline"
                        size="sm"
                        mr={2}
                        colorScheme="yellow"
                      />
                      <IconButton
                        icon={<FaTrash />}
                        aria-label="Delete User"
                        title="Delete User"
                        variant="outline"
                        colorScheme="red"
                        size="sm"
                        onClick={() => handleDelete(user.id)}
                      />
                    </>
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>
                <Text textAlign="center">No users found.</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );

  // Render a loading message if userData is not yet available
  if (!user) {
    return <Text>Loading...</Text>;
  }

  return (
    <Flex direction="column" p={8}>
      <Heading as="h2" size="lg" mb={6}>
        Users
      </Heading>
      <Flex minWidth="max-content" alignItems="center" gap="2" mb={4}>
        <Spacer />
        {(user.role === 'tech' || user.role === 'admin') && (
          <Button
            as={Link}
            to="/app/users/create"
            mt={4}
            colorScheme="green"
            variant="outline"
            size="sm"
          >
            Add User
          </Button>
        )}
      </Flex>

      {isMobile ? (
        <Box>{users.map(renderUserCard)}</Box>
      ) : (
        renderUserTable()
      )}
    </Flex>
  );
};

export default UserList;