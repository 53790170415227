import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SurveyEntry from './SurveyEntry';
import SurveyAcknowledgment from './SurveyAcknowledgment';
import SurveyQuestions from './SurveyQuestions';
import SurveyComplete from './SurveyComplete';
import PublicSurveyGuard from './PublicSurveyGuard';
import SurveyLayout from './SurveyLayout';

const PublicSurveyRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<SurveyLayout><SurveyEntry /></SurveyLayout>} />
      <Route
        path="acknowledgment"
        element={
          <SurveyLayout>
            <PublicSurveyGuard>
              <SurveyAcknowledgment />
            </PublicSurveyGuard>
          </SurveyLayout>
        }
      />
      <Route
        path="questions"
        element={
          <SurveyLayout>
            <PublicSurveyGuard>
              <SurveyQuestions />
            </PublicSurveyGuard>
          </SurveyLayout>
        }
      />
      <Route
        path="complete"
        element={
          <SurveyLayout>
            <PublicSurveyGuard>
              <SurveyComplete />
            </PublicSurveyGuard>
          </SurveyLayout>
        }
      />
    </Routes>
  );
};

export default PublicSurveyRoutes;