import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  TableContainer,
  Tooltip,
  Spacer,
} from '@chakra-ui/react';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useQuestions } from '../../contexts/QuestionsContext';
import { Link, useParams } from 'react-router-dom';
import axios from '../../axiosConfig';

const QuestionsList = () => {
  const { surveyId, pageId } = useParams();
  const { questions, deleteQuestion, fetchQuestions } = useQuestions();
  const [surveyPage, setSurveyPage] = useState({ name: '', survey_name: '', position: '' });

  useEffect(() => {
    const fetchSurveyDetails = async () => {
      try {
        const response = await axios.get(`/surveys/${surveyId}/survey_pages/${pageId}`);
        const { survey_name, page, questions: fetchedQuestions } = response.data;

        setSurveyPage({ name: page.name, survey_name, position: page.position });
        if (fetchedQuestions) {
          fetchQuestions(); // Ensure context fetches the updated questions
        }
      } catch (error) {
        console.error('Error fetching survey details:', error);
      }
    };

    fetchSurveyDetails();
  }, [surveyId, pageId, fetchQuestions]);

  const renderCustomizationsSummary = (customizations) => {
    if (!customizations || customizations.length === 0) {
      return 'None';
    }

    const visibleCount = customizations.filter((c) => c.visible).length;
    const hiddenCount = customizations.filter((c) => !c.visible).length;

    const summary = `${visibleCount} visible, ${hiddenCount} hidden`;
    const details = customizations
      .map(
        (c) =>
          `${c.survey_group_name || `Group ${c.survey_group_id}`}: ${
            c.custom_question_text ? `"${c.custom_question_text}"` : 'Default'
          }${c.visible ? '' : ' (Hidden)'}`
      )
      .join('; ');

    return (
      <Tooltip label={details} hasArrow>
        {summary}
      </Tooltip>
    );
  };

  return (
    <Box mt={6}>
      <Heading as="h1" size="lg" mb={6}>
        {surveyPage.name ? `[${surveyPage.position}] ${surveyPage.name}` : 'Questions'}
      </Heading>
      {surveyPage.survey_name && (
        <Heading as="h2" size="md" color="gray.500" mb={4}>
          {surveyPage.survey_name}
        </Heading>
      )}
      <Text fontSize="lg" color="gray.500">Manage Questions for Page {surveyPage.position}</Text>
      <Flex mb={4}>
        <Spacer />
        <Button
          colorScheme="green"
          as={Link}
          size="sm"
          variant="outline"
          to={`/app/surveys/${surveyId}/pages/${pageId}/questions/create`}
        >
          Add Question
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Question Text</Th>
              <Th>Type</Th>
              <Th>Position</Th>
              <Th>Customizations</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array.isArray(questions) && questions.length > 0 ? (
              questions
                .sort((a, b) => a.position - b.position)
                .map((question) => (
                  <Tr key={question.id}>
                    <Td
                      title={question.question_text}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px',
                      }}
                    >
                      {question.question_text}
                    </Td>
                    <Td>{question.question_type}</Td>
                    <Td>{question.position}</Td>
                    <Td>{renderCustomizationsSummary(question.customizations)}</Td>
                    <Td>
                      <Link
                        to={`/app/surveys/${surveyId}/pages/${pageId}/questions/edit/${question.id}`}
                      >
                        <IconButton
                          icon={<FaPencilAlt />}
                          aria-label="Edit Question"
                          title="Edit Question"
                          variant="outline"
                          colorScheme="yellow"
                          size="sm"
                          mr={2}
                        />
                      </Link>
                      <IconButton
                        icon={<FaTrash />}
                        aria-label="Delete Question"
                        title="Delete Question"
                        variant="outline"
                        onClick={() => deleteQuestion(question.id)}
                        size="sm"
                        colorScheme="red"
                      />
                    </Td>
                  </Tr>
                ))
            ) : (
              <Tr>
                <Td colSpan={5} textAlign="center" color="gray.500">
                  No questions found.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default QuestionsList;