import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  RadioGroup,
  Radio,
  Text,
  Progress,
  Stack,
  Card,
  CardBody,
  SimpleGrid,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

const SurveyQuestions = () => {
  const { endpoint } = useParams();
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [pageInfo, setPageInfo] = useState({ position: 0, title: '' });
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session'));
    if (!surveySession?.group || !surveySession?.role) {
      navigate('../acknowledgment');
    }
  }, [navigate]);

  const fetchQuestions = debounce(async (page = null) => {
    try {
      const surveySession = JSON.parse(localStorage.getItem('survey_session'));
      const response = await axios.get(`/public_surveys/${endpoint}/questions`, {
        params: {
          uuid: surveySession.uuid,
          group_id: surveySession.group,
          page,
        },
      });

      if (response.data.completed) {
        navigate('../complete');
        return;
      }

      const { page: newPageInfo, questions: newQuestions } = response.data;
      setPageInfo(newPageInfo);
      setQuestions(newQuestions);

      const initialAnswers = {};
      newQuestions.forEach((q) => {
        initialAnswers[q.id] = q.question_type === 'slider' ? 4 : '';
      });
      setAnswers(initialAnswers);

      const totalPages = response.data.total_pages || 1;
      setProgress(((newPageInfo.position - 1) / totalPages) * 100);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  }, 300);

  useEffect(() => {
    fetchQuestions();
  }, [endpoint]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSubmit = async () => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session'));
    const unanswered = Object.values(answers).some((value) => value === null || value === '');

    if (unanswered) {
      alert(t('public_survey.questions.unansweredAlert'));
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`/public_surveys/${endpoint}/answers`, {
        uuid: surveySession.uuid,
        group_id: surveySession.group,
        responses: answers,
      });

      if (response.data.completed) {
        navigate('../complete');
      } else {
        fetchQuestions(response.data.next_page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (error) {
      console.error('Error submitting answers:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={6} mx="auto" maxW="3xl">
      <Box mb={6} textAlign="center">
        <Text fontSize="3xl" fontWeight="bold">
          {pageInfo.title}
        </Text>
        <Progress value={progress} size="md" colorScheme="blue" bgColor="#4EB9C5" variant="outline" mt={2} />
      </Box>

      <VStack spacing={6}>
        {questions.map((q) => (
          <Card key={q.id} shadow="md" p={4} w="full" borderWidth="1px" borderRadius="md">
            <CardBody>
              <FormControl>
                <FormLabel>{q.question_text}</FormLabel>
                {q.question_type === 'slider' ? (
                  <Box position="relative" pt={10}>
                    <Slider
                      min={1}
                      max={7}
                      step={1}
                      colorScheme="blue"
                      value={answers[q.id] || 4}
                      onChange={(val) => handleAnswerChange(q.id, val)}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb boxShadow="md" bgColor="#e70" />
                    </Slider>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={4}
                      fontSize="sm"
                      color="gray.600"
                    >
                      <Text>{t('public_survey.questions.stronglyDisagree')}</Text>
                      <Text>{t('public_survey.questions.stronglyAgree')}</Text>
                    </Box>
                  </Box>
                ) : q.question_type === 'bullets' ? (
                  <>
                    <RadioGroup
                      value={answers[q.id]?.toString() || ''}
                      onChange={(val) => handleAnswerChange(q.id, parseInt(val, 10))}
                    >
                      <Stack direction="row" spacing={3} justify="center">
                        {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                          <Radio key={num} value={num.toString()} mx={1.5}>
                            {num}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      mt={2}
                      fontSize="sm"
                      color="gray.600"
                    >
                      <Text>{t('public_survey.questions.stronglyDisagree')}</Text>
                      <Text>{t('public_survey.questions.stronglyAgree')}</Text>
                    </Box>
                  </>
                ) : q.question_type === 'buttons' ? (
                  <SimpleGrid columns={7} spacing={2}>
                    {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                      <Button
                        key={num}
                        colorScheme={answers[q.id] === num ? 'blue' : 'gray'}
                        onClick={() => handleAnswerChange(q.id, num)}
                        size="sm"
                      >
                        {num}
                      </Button>
                    ))}
                  </SimpleGrid>
                ) : null}
              </FormControl>
            </CardBody>
          </Card>
        ))}
      </VStack>

      <Box textAlign="center" mt={10}>
        <Button
          colorScheme="teal"
          width="full"
          variant="outline"
          onClick={handleSubmit}
          isLoading={loading}
          loadingText={t('public_survey.questions.loading')}
          isDisabled={loading}
          _hover={{ bg: 'teal.600', color: 'white' }}
          _focus={{ boxShadow: 'outline' }}
        >
          {t('public_survey.questions.next')}
        </Button>
      </Box>
    </Box>
  );
};

export default SurveyQuestions;