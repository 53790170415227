import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  Heading,
  List,
  ListItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Select,
  VStack,
  useDisclosure,
  useBreakpointValue
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from '../../axiosConfig';

const ResultsPage = () => {
  const { organizationId } = useParams();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { isOpen, onToggle } = useDisclosure();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedPageIndex, setExpandedPageIndex] = useState(null);
  const [error, setError] = useState(null);
  
  // Export settings
  const [selectedFormat, setSelectedFormat] = useState('csv_semi'); // CSV Format
  const [sortBy, setSortBy] = useState('none'); // Sorting (role, group, none)
  const [includeMeans, setIncludeMeans] = useState(false); // Include means
  const [surveyCompleted, setSurveyCompleted] = useState('all'); // Filter: completed, in_progress, all

  const fetchResults = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/organizations/${organizationId}/results`);
      setResults(response.data);
    } catch (error) {
      console.error('Failed to fetch results:', error);
      setError('Failed to load survey results. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = async (organizationSurveyId, advanced = false) => {
    try {
      const params = {
        organization_survey_id: organizationSurveyId,
        format: selectedFormat,
      };

      // If using advanced settings, include extra parameters
      if (advanced) {
        params.sort_by = sortBy;
        params.include_means = includeMeans;
        params.completed = surveyCompleted;
      }

      const response = await axios.get(
        `/organizations/${organizationId}/export_results${advanced ? '_advanced' : ''}`,
        { params, responseType: 'blob' }
      );

      const now = new Date();
      const timestamp = now.toISOString().replace(/[-:T]/g, '').slice(0, 14);
      const filename = `survey_results_${organizationSurveyId}_${timestamp}.csv`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Failed to download CSV:', error);
    }
  };

  useEffect(() => {
    fetchResults();
  }, []);

  if (error) {
    return <Box color="red.500">{error}</Box>;
  }

  return (
    <Box p={6}>
      <Heading mb={6}>Survey Results</Heading>
      {loading ? (
        <Spinner />
      ) : (
        results.map((survey, surveyIndex) => (
          <Box key={surveyIndex} mb={8}>
            <Heading size="md">{survey.surveyName}</Heading>

            {/* Standard and Advanced Export */}
            <Flex mt={2} gap={2}>
              <Select 
                value={selectedFormat} 
                onChange={(e) => setSelectedFormat(e.target.value)} 
                width="auto"
              >
                <option value="csv_comma">CSV (Comma-Separated)</option>
                <option value="csv_semi">CSV (Semicolon-Separated)</option>
              </Select>
              <Button colorScheme="blue" onClick={() => downloadCSV(survey.surveyId)}>
                Download
              </Button>
              <Button colorScheme="gray" variant="outline" onClick={onToggle}>
                Advanced Export
              </Button>
            </Flex>

            {/* Advanced Export Options */}
            <Collapse in={isOpen} animateOpacity>
              <Box mt={4} p={4} borderWidth="1px" borderRadius="md" bgColor="white">
                <VStack align="start" spacing={3}>
                  <Heading size="sm">Export Options</Heading>
                  <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                    <option value="none">No Sorting</option>
                    <option value="group">Sort by Role</option>
                    <option value="role">Sort by Team</option>
                  </Select>
                  <Checkbox isChecked={includeMeans} onChange={(e) => setIncludeMeans(e.target.checked)}>
                    Include Question Means
                  </Checkbox>
                  <Select value={surveyCompleted} onChange={(e) => setSurveyCompleted(e.target.value)}>
                    <option value="all">Include All Surveys</option>
                    <option value="completed">Only Completed</option>
                    <option value="in_progress">Only In-Progress</option>
                  </Select>
                  <Button colorScheme="blue" onClick={() => downloadCSV(survey.surveyId, true)}>
                    Download Advanced CSV
                  </Button>
                </VStack>
              </Box>
            </Collapse>

            <List spacing={4} mt={4}>
              {survey.pages.map((page, pageIndex) => (
                <ListItem key={pageIndex} border="1px" borderColor="gray.200" p={4}>
                  <Flex justify="space-between" align="center">
                    <Button
                      variant="link"
                      onClick={() =>
                        setExpandedPageIndex(expandedPageIndex === pageIndex ? null : pageIndex)
                      }
                    >
                      {page.pageName}
                    </Button>
                    <Text fontWeight="bold">Overall Average: {page.overallAverage || 'N/A'}</Text>
                  </Flex>
                  <Collapse in={expandedPageIndex === pageIndex} animateOpacity>
                    {isMobile ? (
                      // Card layout for mobile
                      page.questions.map((question, questionIndex) => (
                        <Box
                          key={questionIndex}
                          p={4}
                          border="1px"
                          borderColor="gray.200"
                          borderRadius="md"
                          mt={2}
                        >
                          <Text fontWeight="bold">{question.questionText}</Text>
                          <Text>Average Score: {question.averageScore.toFixed(2)}</Text>
                          <Text>Respondent Count: {question.respondentCount}</Text>
                          <Text>Distribution: {JSON.stringify(question.distribution)}</Text>
                        </Box>
                      ))
                    ) : (
                      // Table layout for larger screens
                      <Table variant="simple" size="sm" mt={4}>
                        <Thead>
                          <Tr>
                            <Th>Question</Th>
                            <Th>Average Score</Th>
                            <Th>Respondent Count</Th>
                            <Th>Distribution</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {page.questions.map((question, questionIndex) => (
                            <Tr key={questionIndex}>
                              <Td>{question.questionText}</Td>
                              <Td>{question.averageScore.toFixed(2)}</Td>
                              <Td>{question.respondentCount}</Td>
                              <Td>{JSON.stringify(question.distribution)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    )}
                  </Collapse>
                </ListItem>
              ))}
            </List>
          </Box>
        ))
      )}
    </Box>
  );
};

export default ResultsPage;