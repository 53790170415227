import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import axios from "../../../axiosConfig";

const OrgDashboard = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [stats, setStats] = useState({
    totalSurveys: 0,
    totalRespondents: 0,
    completedSurveys: 0,
    completionRate: 0,
    responseTrends: [],
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get("/organizations/me/stats"); // API call for Hub stats
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching stats:", error);
        toast({
          title: "Error",
          description: "Could not load organization stats.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchStats();
  }, [toast]);

  // Prepare chart data
  const chartData = {
    labels: stats.responseTrends.map((trend) => trend.date),
    datasets: [
      {
        label: t("hub.dashboard.surveyResponses"),
        data: stats.responseTrends.map((trend) => trend.responses),
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };

  return (
    <Box>
      {/* Welcome Message */}
      <Heading as="h1" size="xl">
        {t("hub.dashboard.welcome")}
      </Heading>
      <Text fontSize="lg" mt={2}>
        {t("hub.dashboard.overview")}
      </Text>

      {/* Stats Section */}
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6} mt={6}>
        <Stat>
          <StatLabel>{t("hub.dashboard.totalSurveys")}</StatLabel>
          <StatNumber>{stats.totalSurveys}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>{t("hub.dashboard.totalRespondents")}</StatLabel>
          <StatNumber>{stats.totalRespondents}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>{t("hub.dashboard.completedSurveys")}</StatLabel>
          <StatNumber>{stats.completedSurveys}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>{t("hub.dashboard.completionRate")}</StatLabel>
          <StatNumber>{stats.completionRate}%</StatNumber>
        </Stat>
      </SimpleGrid>

      {/* Survey Trends Graph */}
      <Box mt={8}>
        <Heading as="h3" size="md" mb={4}>
          {t("hub.dashboard.surveyTrends")}
        </Heading>
        {stats.responseTrends.length > 0 ? (
          <Bar data={chartData} />
        ) : (
          <Text>{t("hub.dashboard.noData")}</Text>
        )}
      </Box>
    </Box>
  );
};

export default OrgDashboard;