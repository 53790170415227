import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SurveyPageForm from './SurveyPageForm';
import { SurveyPagesProvider, useSurveyPages } from '../../contexts/SurveyPagesContext';
import { Box, Heading, Text } from '@chakra-ui/react';
import axios from '../../axiosConfig';

const SurveyPageCreateContent = () => {
  const { surveyId } = useParams();
  const { createPage } = useSurveyPages();
  const navigate = useNavigate();
  const [surveyName, setSurveyName] = useState('');

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(`/surveys/${surveyId}`);
        setSurveyName(response.data.name);
      } catch (error) {
        console.error('Error fetching survey:', error);
      }
    };
    fetchSurvey();
  }, [surveyId]);

  const handleCreatePage = async (pageData) => {
    await createPage(pageData);
    navigate(`/app/surveys/${surveyId}/pages`);
  };

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={4}>
        Survey: {surveyName}
      </Heading>
      <Text fontSize="lg" mb={4} color="gray.500">Creating new Page</Text>
      {surveyName ? (
        <SurveyPageForm
          initialData={null}
          onSubmit={handleCreatePage}
          onCancel={() => navigate(`/app/surveys/${surveyId}/pages`)}
        />
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  );
};

const SurveyPageCreate = () => {
  const { surveyId } = useParams();

  return (
    <SurveyPagesProvider surveyId={surveyId}>
      <SurveyPageCreateContent />
    </SurveyPagesProvider>
  );
};

export default SurveyPageCreate;