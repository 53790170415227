import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useUser } from "../../contexts/UserContext";

const ProtectedRoute = ({ children, requiredRoles = [] }) => {
  const authToken = Cookies.get("hkeAuthToken");
  const { user, isLoading } = useUser();
  const location = useLocation();

  // 🛑 Prevent premature redirects by waiting until the user is loaded
  if (isLoading) {
    console.log("User data is still loading...");
    return null; // Prevents rendering before user is fetched
  }

  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  // Check token expiration
  const tokenExpiryTime = 3600 * 1000;
  const tokenCreationTime = Cookies.get("tokenCreationTime");

  if (Date.now() - tokenCreationTime > tokenExpiryTime) {
    console.warn("Token expired. Redirecting to login.");
    Cookies.remove("hkeAuthToken");
    Cookies.remove("tokenCreationTime");
    return <Navigate to="/login" replace />;
  }

  console.log(`Current user.role: '${user?.role}'`);
  
  // 🔹 Prevent infinite redirects by checking if the user is already in the correct section
  const isHubUser = user?.role === "org_user";
  const isAppUser = ["tech", "admin", "user"].includes(user?.role);
  
  if (!requiredRoles.includes(user?.role)) {
    if (isHubUser && !location.pathname.startsWith("/hub")) {
      console.warn(`Redirecting 'org_user' to /hub/dashboard`);
      return <Navigate to="/hub/dashboard" replace />;
    }

    if (isAppUser && !location.pathname.startsWith("/app")) {
      console.warn(`Redirecting '${user?.role}' to /app/dashboard`);
      return <Navigate to="/app/dashboard" replace />;
    }

    // 🚨 If role is unknown, log them out
    console.error(`Unrecognized role: '${user?.role}'. Logging out.`);
    Cookies.remove("hkeAuthToken");
    Cookies.remove("tokenCreationTime");
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;