import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Text,
  VStack,
  Card,
  CardBody,
  CardHeader,
  Heading,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const languageMapping = {
  english: 'en',
  swedish: 'sv',
};

const SurveyEntry = () => {
  const { endpoint } = useParams();
  const { t, i18n } = useTranslation();
  const [passcode, setPasscode] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [error, setError] = useState(null);
  const [honeyPot, setHoneyPot] = useState(''); // Honeypot for bots
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false); // Prevent duplicate API calls

  useEffect(() => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};

    // If session data exists and matches the endpoint, use it
    if (surveySession.organizationName && surveySession.endpoint === endpoint) {
      setOrganizationName(surveySession.organizationName);
      if (surveySession.language) {
        i18n.changeLanguage(surveySession.language); // Set the language
      }
      if (surveySession.valid) {
        navigate('acknowledgment');
        return;
      }
    }

    // Fetch survey details if not already loaded
    if (!isLoaded) {
      const fetchSurveyDetails = async () => {
        try {
          const response = await axios.get(`/public_surveys/${endpoint}`);
          const { organization_name, language } = response.data;

          const languageCode = languageMapping[language.toLowerCase()] || 'en';
          i18n.changeLanguage(languageCode); // Dynamically set the language

          setOrganizationName(organization_name || '');
          localStorage.setItem(
            'survey_session',
            JSON.stringify({
              ...surveySession,
              organizationName: organization_name,
              language: languageCode,
              endpoint,
            })
          );

          setIsLoaded(true);
        } catch (error) {
          console.error('Error fetching survey details:', error);
        }
      };

      fetchSurveyDetails();
    }
  }, [endpoint, navigate, i18n, isLoaded]);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`/public_surveys/${endpoint}/validate_passcode`, {
        passcode,
        name: honeyPot,
      });

      if (response.status === 200) {
        const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
        localStorage.setItem(
          'survey_session',
          JSON.stringify({ ...surveySession, endpoint, valid: true })
        );
        navigate('acknowledgment');
      }
    } catch (error) {
      setError(error.response?.data?.error || t('public_survey.errors.invalidPasscode'));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Box p={6} mx="auto" maxW="lg">
      {/* Hero Section */}
      <VStack spacing={6}>
        <Flex justify="center" align="center" direction="column">
          <Image src="/images/logo-md.png" alt="HKE Learning Logo" mb={4} />
          <Heading size="lg" color={useColorModeValue('teal.600', 'teal.300')} textAlign="center">
            {organizationName || t('public_survey.entry.defaultTitle')}
          </Heading>
        </Flex>

        <Card border="1px" borderColor="gray.200" shadow="md" p={4}>
          <CardHeader>
            <Heading size="md" textAlign="center" color="gray.700">
              {t('public_survey.entry.enterPasscode')}
            </Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={4}>
              {/* Honeypot Field */}
              <VisuallyHidden>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    value={honeyPot}
                    onChange={(e) => setHoneyPot(e.target.value)}
                    autoComplete="off"
                    tabIndex="-1"
                  />
                </FormControl>
              </VisuallyHidden>

              <FormControl>
                <FormLabel>{t('public_survey.entry.passcodeLabel')}</FormLabel>
                <Input
                  boxShadow="md"
                  value={passcode}
                  onChange={(e) => setPasscode(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
              {error && (
                <Text color="red.500" fontSize="sm">
                  {error}
                </Text>
              )}
              <Button
                colorScheme="teal"
                width="full"
                variant="outline"
                onClick={handleSubmit}
                _hover={{ bg: 'teal.600', color: 'white' }}
                _focus={{ boxShadow: 'outline' }}
              >
                {t('public_survey.entry.startSurvey')}
              </Button>
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
};

export default SurveyEntry;