import React, { useEffect, useState } from 'react';
import { Box, Badge, Button, Flex, Heading, List, ListItem, Spinner, Text, VStack } from '@chakra-ui/react';
import axios from '../axiosConfig';
import Header from '../site/components/Header';
import SlimFooter from '../site/components/SlimFooter';

const StatusPage = () => {
  const [healthStatus, setHealthStatus] = useState(null);
  const [toolsStatus, setToolsStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchStatus = async () => {
    setLoading(true);
    setError(null);

    try {
      const [healthResponse, toolsResponse] = await Promise.all([
        axios.get('/health_check'),
        axios.get('/automated_tools_status'),
      ]);

      setHealthStatus(healthResponse.data || {});
      setToolsStatus(toolsResponse.data || {});
    } catch (err) {
      setError('Failed to fetch status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch status on mount
  useEffect(() => {
    fetchStatus();
  }, []);

  const renderStatusBadge = (status) => {
    switch (status) {
      case 'OK':
        return <Badge colorScheme="green">OK</Badge>;
      case 'Active':
        return <Badge colorScheme="blue">Active</Badge>;
      case 'Processing':
        return <Badge colorScheme="cyan">Processing</Badge>;
      case 'Idle':
        return <Badge colorScheme="yellow">Idle</Badge>;
      case 'Outage':
        return <Badge colorScheme="red">Outage</Badge>;
      default:
        return <Badge colorScheme="gray">Unknown</Badge>;
    }
  };

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      {/* Header */}
      <Header />

      {/* Main content */}
      <Box flex="1" p={6} mt={20} mx="auto">
        <Heading mb={6} textAlign="center">
          System Status
        </Heading>
        {loading ? (
          <Spinner size="lg" />
        ) : error ? (
          <Text color="red.500" textAlign="center">{error}</Text>
        ) : (
          <VStack spacing={6} align="stretch">
            <List spacing={4} w="full">
              {/* Server Status */}
              <ListItem
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                p={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text fontWeight="bold" fontSize="lg">Server</Text>
                  <Text>Status: {healthStatus?.server || 'Unknown'}</Text>
                </Box>
                {renderStatusBadge(healthStatus?.server)}
              </ListItem>

              {/* Database Status */}
              <ListItem
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                p={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text fontWeight="bold" fontSize="lg">Database</Text>
                  <Text>Status: {healthStatus?.database || 'Unknown'}</Text>
                </Box>
                {renderStatusBadge(healthStatus?.database)}
              </ListItem>

              {/* Automated Tools Status */}
              <ListItem
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                p={6}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text fontWeight="bold" fontSize="lg">Automated Tools</Text>
                  {/* 
                    <Text>Processed: {toolsStatus?.processed || 0}</Text>
                    <Text>Failed: {toolsStatus?.failed || 0}</Text>
                    <Text>Enqueued: {toolsStatus?.enqueued || 0}</Text>
                    <Text>Workers: {toolsStatus?.active_jobs || 0}</Text>
                    <Text>Redis: {toolsStatus?.redis_status || 'Unknown'}</Text>
                   */}
                </Box>
                {renderStatusBadge(toolsStatus?.status)}
              </ListItem>
            </List>

            {/* Refresh Button */}
            <Button onClick={fetchStatus} colorScheme="teal" mt={6}>
              Refresh Status
            </Button>
          </VStack>
        )}
      </Box>

      {/* Footer */}
      <SlimFooter />
    </Box>
  );
};

export default StatusPage;