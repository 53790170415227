import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SlimFooter = () => {
  const { t } = useTranslation();

  return (
    <Box
      as="footer"
      py={4}
      textAlign="center"
      color="rgba(255, 255, 255, 0.75)"
      bgImage="linear-gradient(to-tr, #4361c2, #4fa49a 50%, #9dc66b 95%)"
      bgBlendMode="overlay"
      bgPosition="top left, center center, center center"
      bgAttachment="fixed, fixed, fixed"
      bgSize="auto, cover, cover"
    >
      <Flex justify="center" align="center" direction={{ base: 'column', md: 'row' }} gap={2}>
        <Text fontSize="sm" color="white">
          &copy; {new Date().getFullYear()} HKE Learning. All rights reserved.
        </Text>
      </Flex>
    </Box>
  );
};

export default SlimFooter;