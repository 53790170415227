import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import CookieConsentModal from './components/layout/CookieConsentDrawer';
import { UserProvider } from './contexts/UserContext';
import { SurveyProvider } from './contexts/SurveyContext';
import customTheme from './theme';

import LoginPage from './components/account/LoginPage';
import Website from './site/components/Website';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';
import Profile from './components/account/Profile';
import Settings from './components/account/Settings';
import ChangePassword from './components/account/ChangePassword';
import OrganizationRoutes from './components/organization/OrganizationRoutes';
import SurveyRoutes from './components/survey/SurveyRoutes';
import UserRoutes from './components/users/UserRoutes';
import MessagesList from './components/MessagesList';
import ProtectedRoute from './components/layout/ProtectedRoute';
import ProtectedLayout from './components/layout/ProtectedLayout';
import PublicSurveyRoutes from './components/public_surveys/PublicSurveyRoutes';
import ResourceArticle from './site/components/ResourceArticle';
import Dashboard from './components/Dashboard';
import StatusPage from './components/StatusPage';
import ResetPasswordRequestPage from './components/account/ResetPasswordRequestPage';
import ResetPasswordPage from './components/account/ResetPasswordPage';

// New Hub Imports
import OrgProtectedLayout from './components/hub/layout/OrgProtectedLayout';
import OrgDashboard from './components/hub/dashboard/OrgDashboard';
import OrgSurveys from './components/hub/surveys/OrgSurveys';
import OrgSettings from './components/hub/settings/OrgSettings';
import DevToolsRoutes from './components/dev_tools/DevToolsRoutes';
import OrgProfile from './components/hub/profile/OrgProfile';
import SurveyConfigurationPage from './components/organization/SurveyConfigurationPage';
import OrgResults from './components/hub/results/OrgResults';

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
      <Router>
        <CookieConsentModal />
        <Routes>
          {/* Public website route */}
          <Route path="/resources/school-effectiveness-survey" element={<ResourceArticle />} />
          <Route path="/" element={<Website />} />

          {/* Public surveys route */}
          <Route path="/survey/:endpoint/*" element={<PublicSurveyRoutes />} />

          {/* Public status page route */}
          <Route path="/status" element={<StatusPage />} />

          {/* Login route (wrapped in UserProvider) */}
          <Route path="/login" element={
            <UserProvider>
              <LoginPage />
            </UserProvider>
          } />

          <Route path="/password/forgot" element={<ResetPasswordRequestPage />} />
          <Route path="/password/reset/:token" element={<ResetPasswordPage />} />

          {/* 🔹 Admin/Internal routes - /app */}
          <Route path="/app" element={
            <UserProvider>
              <ProtectedRoute requiredRoles={["tech", "admin", "user"]}>
                <ProtectedLayout />
              </ProtectedRoute>
            </UserProvider>
          }>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="organizations/*" element={<OrganizationRoutes />} />
            <Route path="users/*" element={<UserRoutes />} />
            <Route path="messages" element={<MessagesList />} />
            <Route path="surveys/*" element={
              <SurveyProvider>
                <SurveyRoutes />
              </SurveyProvider>
            } />
            <Route path="profile" element={<Profile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="password" element={<ChangePassword />} />
            <Route path="devtools/*" element={<DevToolsRoutes />} />

          </Route>

          {/* 🔹 Organization Hub routes - /hub */}
          <Route path="/hub" element={
            <UserProvider>
              <ProtectedRoute requiredRoles={["org_user"]}>
                <OrgProtectedLayout />
              </ProtectedRoute>
            </UserProvider>
          }>
            <Route path="dashboard" element={<OrgDashboard />} />
            <Route path="surveys/configuration/:organizationSurveyId" element={<SurveyConfigurationPage />} />
            <Route path="surveys" element={<OrgSurveys />} />
            <Route path="settings" element={<OrgSettings />} />
            <Route path="results" element={<OrgResults />} />
            <Route path="password" element={<ChangePassword />} />
            <Route path="profile" element={<OrgProfile />} />
          </Route>

          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
          <Route path="/server-error" element={<ServerError />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;