import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Text,
  VStack,
  useToast,
  Divider,
  Input,
  VisuallyHidden,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

const SurveyAcknowledgment = () => {
  const { endpoint } = useParams();
  const { t } = useTranslation();
  const [openingMessage, setOpeningMessage] = useState('');
  const [group, setGroup] = useState('');
  const [role, setRole] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [honeyPot, setHoneyPot] = useState(''); // Honeypot for bots
  const navigate = useNavigate();
  const toast = useToast();

  const MULTIPLE_TEAMS = ["I belong to multiple teams", "Jag tillhör både ett arbetslag och en PLC-grupp"];

  const fetchAcknowledgmentDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/public_surveys/${endpoint}/acknowledgment`);
      const { opening_message, groups, roles } = response.data;

      setOpeningMessage(opening_message || t('public_survey.acknowledgment.defaultMessage'));
      setGroups(groups || []);
      setRoles(roles || []);
    } catch (error) {
      console.error('Error fetching acknowledgment details:', error);
    }
  }, [endpoint, t]);

  useEffect(() => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session'));
    if (surveySession?.group && (surveySession.role || surveySession.selectedRoles?.length)) {
      navigate('../questions');
    }
  }, [navigate]);

  useEffect(() => {
    fetchAcknowledgmentDetails();
  }, [fetchAcknowledgmentDetails]);

  const handleNext = async () => {
    const groupName = groups.find((gArr) => gArr[0] == group)?.[1] || '';
    const finalRoleValue = MULTIPLE_TEAMS.includes(groupName)
      ? selectedRoles.join(', ')
      : role;

    try {
      const response = await axios.post(`/public_surveys/${endpoint}/respondent`, {
        group_id: group,
        role: finalRoleValue,
        email: honeyPot,
      });

      const { uuid } = response.data;
      const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
      localStorage.setItem(
        'survey_session',
        JSON.stringify({
          ...surveySession,
          group,
          role: finalRoleValue,
          selectedRoles,
          uuid,
        })
      );

      navigate('../questions');
    } catch (error) {
      toast({
        title: t('public_survey.acknowledgment.error.title'),
        description:
          error.response?.data?.error ||
          t('public_survey.acknowledgment.error.description'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && group && (role || selectedRoles.length > 0)) {
      handleNext();
    }
  };

  const toggleRole = (clickedRole) => {
    setSelectedRoles((prevSelected) =>
      prevSelected.includes(clickedRole)
        ? prevSelected.filter((r) => r !== clickedRole)
        : [...prevSelected, clickedRole]
    );
  };

  const groupName = groups.find((gArr) => gArr[0] == group)?.[1] || '';
  const isMultipleTeams = MULTIPLE_TEAMS.includes(groupName);

  return (
    <Box p={6} mx="auto" maxW="lg">
      <VStack spacing={6}>
        {/* Hero Section */}
        <Box
          textAlign="center"
          p={6}
          bgGradient="linear(to-r, cyan.500, teal.500)"
          borderRadius="md"
          color="white"
          boxShadow="lg"
        >
          <Heading size="lg" mb={2}>
            {t('public_survey.acknowledgment.welcome')}
          </Heading>
          <Text
            fontSize="md"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(openingMessage) }}
          />
        </Box>

        {/* Privacy Message */}
        <Box
          textAlign="center"
          p={4}
          bg="gray.100"
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          boxShadow="sm"
          maxW="md"
        >
          <Text fontSize="sm" color="gray.600">
            {t('public_survey.acknowledgment.privacyMessage')}
          </Text>
        </Box>

        <Divider />

        {/* Group and Role Selection */}
        <Card shadow="md" border="1px" borderColor="gray.200">
          <CardHeader>
            <Heading size="md" textAlign="center" color="gray.700">
              {t('public_survey.acknowledgment.selectDetails')}
            </Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={4}>
              {/* Honeypot Field (Visually Hidden) */}
              <VisuallyHidden>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="text"
                    value={honeyPot}
                    onChange={(e) => setHoneyPot(e.target.value)}
                    autoComplete="off"
                    tabIndex="-1"
                  />
                </FormControl>
              </VisuallyHidden>

              <FormControl isRequired>
                <FormLabel>{t('public_survey.acknowledgment.selectGroup')}</FormLabel>
                <Select
                  placeholder={t('public_survey.acknowledgment.groupPlaceholder')}
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  onKeyDown={handleKeyDown}
                >
                  {groups.map((g) => (
                    <option key={g[0]} value={g[0]}>
                      {g[1]}
                    </option>
                  ))}
                </Select>
              </FormControl>

              {isMultipleTeams ? (
                <FormControl isRequired>
                  <FormLabel>{t('public_survey.acknowledgment.selectRole')}</FormLabel>
                  {roles.map((r) => (
                    <label key={r.trim()} style={{ display: 'block' }}>
                      <input
                        type="checkbox"
                        value={r}
                        style={{ marginRight: '5px' }}
                        checked={selectedRoles.includes(r.trim())}
                        onChange={() => toggleRole(r.trim())}
                      />
                      {r.trim()}
                    </label>
                  ))}
                </FormControl>
              ) : (
                <FormControl isRequired>
                  <FormLabel>{t('public_survey.acknowledgment.selectRole')}</FormLabel>
                  <Select
                    placeholder={t('public_survey.acknowledgment.rolePlaceholder')}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    onKeyDown={handleKeyDown}
                  >
                    {roles.map((r) => (
                      <option key={r} value={r}>
                        {r}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </VStack>
          </CardBody>
          <CardFooter>
            <Button
              onClick={handleNext}
              colorScheme="teal"
              variant="outline"
              width="full"
              isDisabled={!group || (!role && selectedRoles.length === 0)}
              _hover={{ bg: 'teal.600', color: 'white' }}
            >
              {t('public_survey.acknowledgment.proceed')}
            </Button>
          </CardFooter>
        </Card>
      </VStack>
    </Box>
  );
};

export default SurveyAcknowledgment;