import React, { useState, useEffect } from 'react';
import { Box, Heading, Flex, FormControl, FormLabel, Input, Textarea, Button, VStack, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axiosConfig';

const OrganizationForm = () => {
  const { id } = useParams();
  const [organization, setOrganization] = useState({
    name: '',
    contact: '',
    title: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    notes: ''
  });
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (id) {
      const fetchOrganization = async () => {
        try {
          const response = await axios.get(`/organizations/${id}`);
          setOrganization(response.data);
        } catch (error) {
          console.error('Error fetching organization:', error);
        }
      };
      fetchOrganization();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganization((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/organizations/${id}`, organization);
        toast({ title: 'Organization updated.', status: 'success' });
      } else {
        await axios.post('/organizations', organization);
        toast({ title: 'Organization created.', status: 'success' });
      }
      navigate('/app/organizations');
    } catch (error) {
      console.error('Error saving organization:', error);
      toast({ title: 'Failed to save organization.', status: 'error' });
    }
  };

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>{id ? 'Edit Organization' : 'Create Organization'}</Heading>
      <VStack spacing={4} align="stretch">
        <FormControl isRequired>
          <FormLabel>Organization Name</FormLabel>
          <Input name="name" value={organization.name} onChange={handleChange} />
        </FormControl>

        <FormControl>
          <FormLabel>Contact Person</FormLabel>
          <Input name="contact" value={organization.contact} onChange={handleChange} />
        </FormControl>

        <FormControl>
          <FormLabel>Title (e.g., Manager, Director)</FormLabel>
          <Input name="title" value={organization.title} onChange={handleChange} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input name="email" value={organization.email} onChange={handleChange} />
        </FormControl>

        {['phone', 'address', 'city', 'state', 'country', 'zip'].map((field) => (
          <FormControl key={field}>
            <FormLabel>{field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</FormLabel>
            <Input name={field} value={organization[field]} onChange={handleChange} />
          </FormControl>
        ))}

        <FormControl>
          <FormLabel>Notes</FormLabel>
          <Textarea
            name="notes"
            value={organization.notes}
            onChange={handleChange}
            placeholder="Add any additional details about the organization"
          />
        </FormControl>

        <Flex justifyContent="flex-end" width="100%">
          <Button colorScheme="green" onClick={handleSubmit}>
            {id ? 'Update' : 'Create'} Organization
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default OrganizationForm;