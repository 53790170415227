import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  TableContainer,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useParams, Link } from 'react-router-dom';

const OrganizationSurveys = () => {
  const { organizationId } = useParams();
  const [organization, setOrganization] = useState(null);
  const [surveys, setSurveys] = useState([]);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(`/organizations/${organizationId}`);
        setOrganization(response.data);
      } catch (error) {
        console.error('Error fetching organization:', error);
      }
    };

    const fetchSurveys = async () => {
      try {
        const response = await axios.get(`/organizations/${organizationId}/organization_surveys`);
        setSurveys(response.data);
      } catch (error) {
        console.error('Error fetching surveys:', error);
      }
    };

    fetchOrganization();
    fetchSurveys();
  }, [organizationId]);

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>
        {organization ? organization.name : 'Loading Organization...'}
      </Heading>
      <Text fontSize="lg" color="gray.500">Manage Surveys</Text>

      <TableContainer mt={6}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Survey Name</Th>
              <Th>Language</Th>
              <Th>Associated</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {surveys.map((survey) => {
              const associated = survey.associated_organization_id === parseInt(organizationId, 10);
              return (
                <Tr key={survey.id}>
                  <Td>{survey.name}</Td>
                  <Td>{survey.language}</Td>
                  <Td>{associated ? 'Yes' : 'No'}</Td>
                  <Td>
                    <Button
                      as={Link}
                      to={`/app/organizations/${organizationId}/surveys/${survey.id}/configure`}
                      colorScheme={associated ? 'cyan' : 'green'}
                      size="sm"
                      variant="outline"
                    >
                      {associated ? 'Edit' : 'Configure'}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrganizationSurveys;