import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import OrgNavbar from './OrgNavbar';
import OrgSidebar from './OrgSidebar';

const OrgProtectedLayout = () => {
  return (
    <Flex>
      {/* Sidebar */}
      <Box display={{ base: 'none', md: 'block' }}>
        <OrgSidebar />
      </Box>

      {/* Main Content */}
      <Box ml={{ base: 0, md: '250px' }} width="100%">
        <OrgNavbar />

        {/* Content Area (where pages load) */}
        <Box p={4} className="content-box">
          <Outlet /> {/* This renders the page component for each route */}
        </Box>
      </Box>
    </Flex>
  );
};

export default OrgProtectedLayout;