import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import SurveyPageForm from './SurveyPageForm';
import { SurveyPagesProvider, useSurveyPages } from '../../contexts/SurveyPagesContext';
import { Box, Heading, Text } from '@chakra-ui/react';

const SurveyPageEditContent = () => {
  const { surveyId, pageId } = useParams();
  const { updatePage } = useSurveyPages();
  const [initialData, setInitialData] = useState(null);
  const [surveyName, setSurveyName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurveyAndPage = async () => {
      try {
        const [surveyResponse, pageResponse] = await Promise.all([
          axios.get(`/surveys/${surveyId}`),
          axios.get(`/surveys/${surveyId}/survey_pages/${pageId}`)
        ]);
        console.log('PageResponse..: ', pageResponse)
        setSurveyName(surveyResponse.data.name);
        setInitialData(pageResponse.data.page);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchSurveyAndPage();
  }, [surveyId, pageId]);

  const handleUpdatePage = async (pageData) => {
    await updatePage(pageId, pageData);
    navigate(`/app/surveys/${surveyId}/pages`);
  };

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={4}>
        Survey: {surveyName}
      </Heading>
      <Text fontSize="lg" mb={4} color="gray.500">Editing Page</Text>
      {initialData ? (
        <SurveyPageForm
          initialData={initialData}
          onSubmit={handleUpdatePage}
          onCancel={() => navigate(`/app/surveys/${surveyId}/pages`)}
        />
      ) : (
        <Text>Loading...</Text>
      )}
    </Box>
  );
};

const SurveyPageEdit = () => {
  const { surveyId } = useParams();

  return (
    <SurveyPagesProvider surveyId={surveyId}>
      <SurveyPageEditContent />
    </SurveyPagesProvider>
  );
};

export default SurveyPageEdit;