import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Text,
  VStack,
  HStack,
  Stack,
  Divider,
  Badge,
  useToast,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, BarController, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { Chart } from "react-chartjs-2";
import { FaPencilAlt, FaClipboardList, FaChartBar, FaUser, FaEnvelope } from 'react-icons/fa';
import axios from '../../axiosConfig';

const roleMap = {
  tech: 'Tech',
  admin: 'Admin',
  user: 'User',
  org_user: 'Organization User',
};


// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, LineElement, PointElement, Title, Tooltip, Legend);

const OrganizationView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const [organization, setOrganization] = useState(null);
  const [stats, setStats] = useState({ totalRespondents: 0, completedSurveys: 0 });
  const [surveyResults, setSurveyResults] = useState(null); // Store data for the bar chart
  const [user, setUser] = useState(null); // Store associated user
  const isMobile = useBreakpointValue({ base: true, md: false });
  const bgColor = useColorModeValue("white", "gray.800");

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(`/organizations/${id}`);
        setOrganization(response.data);
      } catch (error) {
        console.error('Error fetching organization:', error);
        toast({
          title: 'Error',
          description: 'Could not load organization details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        navigate('/app/organizations');
      }
    };

    const fetchStats = async () => {
      try {
        const response = await axios.get(`/organizations/${id}/stats`);
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    const fetchSurveyResults = async () => {
      try {
        const response = await axios.get(`/organizations/${id}/survey_results_by_page`);
        setSurveyResults(response.data.surveys); // Set grouped survey results
      } catch (error) {
        console.error('Error fetching survey results:', error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get(`/organizations/${id}/user`);
        setUser(response.data); // Set the associated user if available
      } catch (error) {
        if (error.response?.status === 404) {
          setUser(null); // No user associated with this organization
        } else {
          console.error('Error fetching user:', error);
        }
      }
    };

    fetchOrganization();
    fetchStats();
    fetchSurveyResults();
    fetchUser();
  }, [id, navigate, toast]);

  if (!organization) {
    return <Text>Loading organization details...</Text>;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, 
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Survey Pages",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Response Count",
        },
        position: "left", // Primary Y-axis
      },
      y2: {
        title: {
          display: true,
          text: "Average Score (1-7)",
        },
        position: "right", // Secondary Y-axis
        min: 1,
        max: 7, // Keep this range fixed to make the average line readable
        grid: {
          drawOnChartArea: false, // Ensures y2 grid lines don't overlap y
        },
      },
    },
  };

  const renderCharts = () => {
    if (!surveyResults) return <Text>Loading charts...</Text>;
  
    return surveyResults.map((survey, index) => {
      const labels = survey.pages.map((page) => page.page_title);
  
      // Create datasets for stacked bars (response count per score)
      const scoreDistribution = {};
      survey.pages.forEach((page) => {
        Object.entries(page.distribution).forEach(([score, count]) => {
          if (!scoreDistribution[score]) {
            scoreDistribution[score] = Array(survey.pages.length).fill(0);
          }
          scoreDistribution[score][labels.indexOf(page.page_title)] = count;
        });
      });
  
      const barDatasets = Object.entries(scoreDistribution).map(([score, data], idx) => ({
        label: `Score ${score}`,
        data,
        backgroundColor: `rgba(${(idx * 75) % 255}, ${(idx * 150) % 255}, ${(idx * 200) % 255}, 0.8)`,
        borderWidth: 1,
        yAxisID: "y", // Assign bars to primary Y-axis
      }));
  
      // Create dataset for the average line (assigned to the right y-axis)
      const averageScores = survey.pages.map((page) => page.average_score);
      const lineDataset = {
        label: "Average Score",
        data: averageScores,
        type: "line",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(255, 99, 132, 1)",
        tension: 0.3,
        yAxisID: "y2", // Assign line to secondary Y-axis
      };
  
      const chartData = {
        labels,
        datasets: [...barDatasets, lineDataset],
      };
  
      return (
        <Box
          key={index}
          bg={bgColor}
          p={6}
          shadow="md"
          borderRadius="md"
          mt={6}
          width="100%"
          style={{ height: isMobile ? '50vh' : '400px' }}
        >
          <Heading size="md" mb={4}>
            {survey.survey_name}
          </Heading>
          <Chart type="bar" data={chartData} options={options} />
        </Box>
      );
    });
  };

  return (
    <Box p={6} mx="auto">
      {/* Header Section */}
      <VStack spacing={4} align="start" mb={6}>
        <Heading as="h2" size="lg">{organization.name}</Heading>
        <Text fontSize="md" fontWeight="bold">
          Contact: {organization.contact} {organization.title ? `(${organization.title})` : ''}
        </Text>

        <HStack spacing={3}>
          <Badge colorScheme="blue">{organization.city}</Badge>
          <Badge colorScheme="green">{organization.state}</Badge>
          <Badge colorScheme="purple">{organization.country}</Badge>
        </HStack>
        <Text color="gray.600" fontSize="md">
          {organization.address}
        </Text>
        <Divider />
      </VStack>

      {organization.notes && (
        <Box mb={6} p={4} borderWidth="1px" borderRadius="md" bg={bgColor}>
          <Heading as="h3" size="md" mb={2}>Notes</Heading>
          <Text>{organization.notes}</Text>
        </Box>
      )}

      {/* Associated User Section */}
      {user && (
        <Card mb={6} borderRadius="md" boxShadow="md" p={4} bg={bgColor}>
          <CardHeader>
            <Heading as="h3" size="md">Associated User</Heading>
          </CardHeader>
          <CardBody>
            <VStack align="start" spacing={3}>
              <Text fontWeight="bold">{user.first_name} {user.last_name}</Text>
              <HStack>
                <Icon as={FaEnvelope} />
                <Text>{user.email}</Text>
              </HStack>
              <Badge colorScheme="blue">{roleMap[user.role]}</Badge>
              <Button
                leftIcon={<FaUser />}
                colorScheme="blue"
                as={Link}
                to={`/app/organizations/${id}/user/edit`}
                size="sm"
              >
                Edit User
              </Button>
            </VStack>
          </CardBody>
        </Card>
      )}

      <Divider />

      {/* Stats Section */}
      <Box mb={6}>
        <Heading as="h3" size="md" mb={4}>
          Organization Stats
        </Heading>
        <VStack spacing={3} align="start">
          <Text>
            <strong>Total Respondents:</strong> {stats.totalRespondents}
          </Text>
          <Text>
            <strong>Completed Surveys:</strong> {stats.completedSurveys}
          </Text>
        </VStack>
      </Box>

      <Divider />

      {/* Survey Results Section */}
      {renderCharts()}

      <Divider />

      {/* Actions Section */}
      <Box mt={6}>
        <Heading as="h3" size="md" mb={4}>
          Actions
        </Heading>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={4}
          justify="space-between"
          align={isMobile ? 'stretch' : 'center'}
        >
          <Button
            leftIcon={<FaPencilAlt />}
            colorScheme="yellow"
            variant="outline"
            as={Link}
            to={`/app/organizations/${id}/edit`}
          >
            {isMobile ? 'Edit' : 'Edit Organization'}
          </Button>
          <Button
            leftIcon={<FaClipboardList />}
            colorScheme="cyan"
            variant="outline"
            as={Link}
            to={`/app/organizations/${id}/surveys`}
          >
            {isMobile ? 'Surveys' : 'Manage Surveys'}
          </Button>
          <Button
            leftIcon={<FaChartBar />}
            colorScheme="teal"
            variant="outline"
            as={Link}
            to={`/app/organizations/${id}/results`}
          >
            {isMobile ? 'Results' : 'Survey Results'}
          </Button>
          <Button
            leftIcon={<FaUser />}
            colorScheme="blue"
            variant="outline"
            as={Link}
            to={`/app/organizations/${id}/user/${user ? 'edit' : 'create'}`}
          >
            {user ? (isMobile ? 'Edit User' : 'Edit Associated User') : (isMobile ? 'Add User' : 'Create Associated User')}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default OrganizationView;