import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FeatureToggles from './feature_toggle/FeatureToggles';
import FeatureToggleForm from './feature_toggle/FeatureToggleForm';
import DevToolsDashboard from './DevToolsDashboard';

const DevToolsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DevToolsDashboard />} />
      <Route path="/feature-toggles" element={<FeatureToggles />} />
      <Route path="/feature-toggles/create" element={<FeatureToggleForm />} />
      <Route path="/feature-toggles/edit/:id" element={<FeatureToggleForm />} />
    </Routes>
  );
};

export default DevToolsRoutes;