import { useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Box,
  FormControl,
  FormHelperText,
  useToast,
  useColorModeValue,
  Heading,
  Text,
  InputLeftElement,
  InputRightElement,
  chakra,
} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";
import axios from "../../axiosConfig";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../site/components/Header";
import SlimFooter from "../../site/components/SlimFooter";

const CFaLock = chakra(FaLock);

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { token } = useParams();

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const formBgColor = useColorModeValue("white", "gray.800");
  const inputBgColor = useColorModeValue("gray.50", "gray.600");
  const buttonColorScheme = useColorModeValue("teal", "orange");
  const textColor = useColorModeValue("gray.700", "gray.200");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: t("reset_password.error_title"),
        description: t("reset_password.password_mismatch"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await axios.post("/passwords/reset", { token, password });
      if (response.status === 200) {
        toast({
          title: t("reset_password.success_title"),
          description: t("reset_password.success_description"),
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/login");
      }
    } catch (error) {
      console.error("Reset password error:", error);
      toast({
        title: t("reset_password.error_title"),
        description: t("reset_password.error_description"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex direction="column" minHeight="100vh" backgroundColor={bgColor}>
      {/* Header */}
      <Header />

      {/* Main Content */}
      <Flex
        flex="1"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        {/* Title and Message */}
        <Box mb={8}>
          <Heading as="h1" size="lg" color={textColor} mb={2}>
            {t("reset_password.page_title")}
          </Heading>
          <Text fontSize="md" color={textColor}>
            {t("reset_password.page_description")}
          </Text>
        </Box>

        <Stack flexDir="column" justifyContent="center" alignItems="center">
          <Box minW={{ base: "90%", md: "468px" }}>
            <form onSubmit={handleSubmit}>
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor={formBgColor}
                boxShadow="md"
                borderRadius="8px"
              >
                {/* New Password */}
                <FormControl>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <CFaLock color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder={t("reset_password.new_password_placeholder")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      backgroundColor={inputBgColor}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword
                          ? t("reset_password.hide_password")
                          : t("reset_password.show_password")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                {/* Confirm Password */}
                <FormControl>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <CFaLock color="gray.300" />
                    </InputLeftElement>
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder={t(
                        "reset_password.confirm_password_placeholder"
                      )}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      backgroundColor={inputBgColor}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword
                          ? t("reset_password.hide_password")
                          : t("reset_password.show_password")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormHelperText>
                    {t("reset_password.password_helper")}
                  </FormHelperText>
                </FormControl>

                <Button
                  borderRadius="4px"
                  type="submit"
                  colorScheme={buttonColorScheme}
                  width="full"
                  variant="outline"
                  isLoading={isSubmitting}
                >
                  {t("reset_password.button_text")}
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>

      {/* Footer */}
      <SlimFooter />
    </Flex>
  );
};

export default ResetPasswordPage;