import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const SurveyConfigurationPage = () => {
  const { organizationId, surveyId, organizationSurveyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  // Detect environment (Hub vs. App)
  const isHub = location.pathname.startsWith('/hub');

  // Determine the correct survey identifier
  const surveyIdentifier = organizationSurveyId || surveyId;
  const organizationIdentifier = isHub ? "me" : organizationId;

  const [formData, setFormData] = useState({
    start_date: '',
    end_date: '',
    opening_message: '',
    closing_message: '',
    roles: '',
    passcode: '',
    endpoint: '',
  });

  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const response = await axios.get(
          `/organizations/${organizationIdentifier}/organization_surveys/${surveyIdentifier}`
        );

        setFormData((prev) => ({
          ...prev,
          ...response.data,
          start_date: response.data.start_date || '',
          end_date: response.data.end_date || '',
          opening_message: response.data.opening_message || '',
          closing_message: response.data.closing_message || '',
          roles: response.data.roles || '',
          passcode: response.data.passcode || '',
          endpoint: response.data.endpoint || '',
        }));
      } catch (error) {
        if (error.response?.status !== 404) {
          console.error('Error fetching survey configuration:', error);
        }
      }
    };

    fetchConfiguration();
  }, [organizationIdentifier, surveyIdentifier]);

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `/organizations/${organizationIdentifier}/organization_surveys/${surveyIdentifier}`,
        formData
      );

      toast({
        title: 'Survey configuration saved.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      navigate(isHub ? `/hub/surveys` : `/app/organizations/${organizationId}/surveys`);
    } catch (error) {
      console.error('Error saving survey configuration:', error);
      toast({
        title: 'Failed to save survey configuration.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const generateRandomEndpoint = () => {
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // Exclude ambiguous chars
    const length = 12;
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    result = `${result.substring(0, 4)}-${result.substring(4, 8)}-${result.substring(8, 12)}`;
    handleChange('endpoint', result); // Update the endpoint in formData
  };

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Survey Configuration</Heading>
      <Text fontSize="lg">Manage Survey Settings</Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={4} mt={6}>
          <FormControl>
            <FormLabel>Endpoint</FormLabel>
            <InputGroup size="md">
              <Input
                type="text"
                value={formData.endpoint}
                onChange={(e) => handleChange('endpoint', e.target.value)}
                placeholder="Enter or generate an endpoint"
              />
              <InputRightElement width="6rem">
                <Button colorScheme="cyan" variant="outline" onClick={generateRandomEndpoint}>
                  Generate
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Passcode</FormLabel>
            <Input
              type="text"
              value={formData.passcode}
              onChange={(e) => handleChange('passcode', e.target.value)}
              placeholder="Enter a passcode"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              value={formData.start_date}
              onChange={(e) => handleChange('start_date', e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              value={formData.end_date}
              onChange={(e) => handleChange('end_date', e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Opening Message</FormLabel>
            <Textarea
              value={formData.opening_message}
              onChange={(e) => handleChange('opening_message', e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Closing Message</FormLabel>
            <Textarea
              value={formData.closing_message}
              onChange={(e) => handleChange('closing_message', e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Teams</FormLabel>
            <Textarea
              placeholder="Enter teams separated by commas (,). Example: team1, team2"
              value={formData.roles}
              onChange={(e) => handleChange('roles', e.target.value)}
            />
          </FormControl>

          <Button type="submit" colorScheme="blue">
            Save
          </Button>

          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() => navigate(isHub ? `/hub/surveys` : `/app/organizations/${organizationId}/surveys`)}
          >
            Cancel
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default SurveyConfigurationPage;