import React from 'react';
import { Box, Image, Container, Heading, Text, VStack } from '@chakra-ui/react';
import Header from './Header';
import SlimFooter from './SlimFooter';

const ResourceArticle = () => {
  return (
    <>
      {/* Horizontal Bar */}
      <Box
        w="100%"
        h="10px"
        bgGradient="linear(to-r, #A2BA06, #4EB9C5, #E5923B)"
      />

      <Header />
      {/* Hero Section */}
      <Box as="section" bg="gray.100" py={10}>
        <Container maxW="container.xl" textAlign="center" pt={10}>
          <Image
            src="/images/articles/school-effectiveness-survey.webp"
            alt="School Effectiveness Survey"
            borderRadius="md"
            boxShadow="lg"
            maxHeight="400px"
            objectFit="cover"
            mx="auto"
          />
          <Heading as="h1" mt={8} fontSize={{ base: '2xl', md: '4xl' }} color="gray.700">
            The School Effectiveness Survey
          </Heading>
          <Text mt={4} fontSize={{ base: 'md', md: 'lg' }} color="gray.600">
            Empowering school leadership teams with actionable insights for continuous improvement.
          </Text>
        </Container>
      </Box>
      
      {/* Main Content */}
      <Box as="main" py={20} bg="white">
        <Container maxW="container.lg" fontSize="xl">
          <VStack align="start" spacing={8}>
            <Text>
              The School Effectiveness Survey from HKE Learning is a fast and efficient tool designed to empower school leadership teams in diagnosing and remedying ineffective components of their school's inner workings- the school’s smart core. This one-stop, all-inclusive process provides a streamlined approach that connects schools directly to identified needs and effective solutions, all within a remarkable 30-minute timeframe. By facilitating quick insights into areas needing improvement, the survey enables leaders to act swiftly and strategically, fostering a culture of continuous enhancement and accountability. With its user-friendly design and immediate access to results, the HKE Learning survey serves as an invaluable resource for schools committed to optimising their performance and driving student and teacher success.
            </Text>
            <Heading as="h2">What is the survey?</Heading>
            <Text>
              The School Effectiveness Survey is designed to gather staff perspectives on the inner workings of the school.  It comprises 96 research-backed items that delve into various aspects of operational effectiveness. Utilising a 7-point Likert Scale, the survey invites staff members to express their views on a range of topics, including communication, collaboration, leadership, and school culture. Staff are encouraged to rate their experiences and perceptions, providing valuable insights into how well the school functions and the data helps identify areas for improvement. By capturing a comprehensive snapshot of staff opinions, this survey fosters an environment of continuous growth, enabling school administrators to understand better the effectiveness of their policies and practices from the perspective of those who directly contribute to the school's success.
            </Text>
            <Text>
            Unique to this survey is that it assesses various observable characteristics of schools as an effective organisations, focusing on elements such as collaborative teaching practices, professional development opportunities, and support systems for both educators and students. Participants will be prompted to evaluate statements regarding the extent to which their school promotes a culture of teamwork, encourages continuous learning among staff, and fosters open communication channels between school leadership  and staff. By identifying strengths and areas for improvement, the survey aims to provide actionable insights that can help rectify existing issues, enhance overall school performance, and ultimately support schools to transform.
            </Text>
            <Heading as="h2">The scientific basis of the survey.</Heading>
            <Text>
              This survey is grounded in a robust scientific framework informed by extensive research on school effectiveness and improvement. This framework draws on decades of literature that identifies critical factors influencing educational achievement and student well-being (Hattie 2023; Sharratt, 2019). The survey has been meticulously using findings from prominent studies, ensuring the inclusion of validated constructs that pertain to leadership, instructional quality, and organisational climate (DuFour et al., 2024; Leithwood et al., 2020; Marzano et al., 2014).
            </Text>
            <Text>
              Additionally, the survey incorporates insights from professionals with years of experience of partnering with schools to enhance both student and teacher results and well-being, leveraging their expertise to identify the most impactful elements for evaluation. By aligning the survey’s content with established theories and empirical findings, it aims to provide a meaningful assessment of the school's operational effectiveness.
            </Text>
            <Text>
              The survey's structure is systematically organised according to the Inputs-Processes-Outputs (IPO) model, a framework that clarifies the relationships between various components of school functioning and their impact on overall effectiveness (Bryk et al., 2010; Fullan & Quinn, 2016). In this design, "Inputs" encompass elements which are critical to establishing a strong foundation for educational success. The "Core Processes" category focuses on the internal mechanisms and practices that facilitate day-to-day operations. “Culture and Climate” refer to the collective characteristics, behaviours, or dynamics that arise because of the interactions and processes. Finally, "Outputs" refer to measurable outcomes such as student and teacher achievement, engagement, and well-being.
            </Text>
            <Text>
              By structuring the survey in this manner, attention is directed to a logical sequence of potential interventions, enabling school leaders to identify areas for improvement in inputs and processes that could subsequently enhance student outcomes and teacher development. This organised approach fosters a deeper understanding of how particular aspects of the school can be targeted for growth, ultimately leading to more effective educational practices and improved student and teacher results (Hayes et al., 2024; Robinson, 2017; Snart, 2010; Timperley et al., 2019).
            </Text>
            <Heading as="h2">A model of the school Smart Core</Heading>
            <Text>
              The school's Smart Core consists of interrelated elements that function both independently and collectively to drive operational effectiveness. When these components align and operate synergistically, they create a dynamic environment that fosters sustained improvement and enhances student learning outcomes and teacher effectiveness.<br />
              The school’s Smart Core has 16 interrelated elements represented in the following diagram.
            </Text>
            <Image src="/images/IPO-Diagram.webp" />
            <Heading as="h2">What can the survey be used for?</Heading>
            <Text>
              The survey serves as a diagnostic tool to evaluate the elements of the school's Smart Core, identifying both areas that require improvement, and aspects that are functioning effectively. By systematically gathering feedback from staff, the survey highlights specific strengths and weaknesses within the school's operations, facilitating specific and targeted interventions. While it can be utilised as a standalone data point to assess current conditions, its true value emerges when administered regularly, allowing for a longitudinal analysis of progress over time. This ongoing assessment enables school leaders to track improvements, adapt strategies, and foster a culture of continuous growth, ultimately contributing to the overall effectiveness and responsiveness of the school.
            </Text>
            <Heading as="h2">What is the effect?</Heading>
            <Text>
              The consistent use of the survey over time has a profoundly positive effect on a school's ability to track improvement and refine its Smart Core operations. By regularly assessing the various components, leaders can accurately diagnose weak points, enabling them to implement timely and specific interventions tailored to address identified challenges. This proactive approach not only enhances the responsiveness of the school's operations but also fosters a culture of accountability and collaboration among staff. As schools gather and analyse survey data over multiple cycles, they can identify trends, celebrate successes, and make informed decisions that contribute to sustained growth and development, ultimately leading to enhanced student outcomes and a more cohesive learning community.
            </Text>
            <Heading as="h2">Which schools need the survey?</Heading>
            <Text>
              All schools, regardless of their current performance level, can benefit from the implementation of the survey as a critical tool for continuous development and effectiveness. In the ever-evolving landscape of education, schools must adapt to changing needs, expectations, and challenges, and the survey provides valuable insights into the dynamics of the smart core that underpin these transformations. Often, improvement initiatives falter due to an inability to effectively address the underlying issues within the school's operations, which can hinder the capacity to manage complex changes over time. By utilising the survey, schools can gain a clearer understanding of their strengths and weaknesses, allowing them to develop targeted strategies that foster resilience and adaptability. In this way, the survey becomes an essential mechanism for all schools to drive meaningful progress and ensuring that all students benefit from a robust and responsive educational environment.
            </Text>
            <Heading as="h2">Can the survey be used with other data gathering methods?</Heading>
            <Text>
              Utilising the survey in conjunction with other tools significantly enhances a school's capacity for improvement and development by providing a more comprehensive view of its performance. When combined with student achievement data, the survey offers crucial insights that enable educators to take a targeted and proactive approach to addressing the Smart Core while simultaneously focusing in on specific areas of the curriculum. This integration facilitates a nuanced understanding of how different factors interact within the educational environment, allowing schools to identify not only what changes are necessary but also where to implement them effectively. 
            </Text>
            <Heading as="h2">How is the survey implemented?</Heading>
            <Text>
              Implementing the survey is designed to be efficient and straightforward, taking less than 30 minutes to complete. Ideally, it is most effective when all staff members complete it simultaneously, minimising opportunities for collaboration and ensuring the integrity of individual responses. The survey specifically prompts respondents to reflect on the school, as well as their specific teams, fostering a comprehensive understanding of the educational environment. Utilising a 7-point Likert scale, participants are encouraged to respond to each statement based on their gut feelings, providing authentic insights into their perceptions and experiences. Results are delivered immediately after the survey is completed, allowing schools to diagnose their strengths and weaknesses in real-time, thus equipping them with the critical information needed to drive targeted improvements and strategic decision-making effectively. This immediate feedback loop ensures that the survey serves as a powerful tool for continuous growth and development within the institution.
            </Text>
            <Heading as="h2">Do you need training in the use of the survey?</Heading>
            <Text>
              Completing the survey requires no formal training, making it an accessible tool for all staff members. Following an initial briefing with HKE Learning, the survey is customised for each school based on specific information provided, ensuring relevance and effectiveness. Participants can easily access and complete the survey through the user-friendly HKE Learning Hub. Results and reports are generated immediately, allowing for swift review and analysis.<br />
              Additionally, the HKE Learning team offers online support to help interpret the survey findings, with the option to purchase extra consultation time for further in-depth analysis. As school personnel utilise the survey repeatedly over time, their ability to interpret results becomes increasingly efficient and intuitive.
            </Text>
            <Heading as="h2">What support is provided?</Heading>
            <Text>
              The support accompanying the survey is designed to maximise its effectiveness and ensure meaningful outcomes.<br />
              The initial cost includes one-time access to the survey, a comprehensive digital report detailing the results, and two months of access to the data for ongoing analysis. Additionally, participants benefit from a digital webinar specifically aimed at assisting them in interpreting the survey data effectively.<br />
              For schools seeking more personalised guidance, further consultancy services are available at an additional fee, providing in-depth analysis and expert support for identifying targeted solutions based on the survey findings.<br />
              This robust support framework empowers schools to make informed decisions and implement strategies that enhance their school operation and effectiveness.
            </Text>
            <Heading as="h2">Case studies</Heading>
            <Text as="i">
              At <strong>school A</strong>, the leadership team implemented the Effective Schools Survey to comprehensively assess their current practices and identify areas for improvement.  The survey results revealed that while the school staff had high confidence in school management, there were significant gaps in a shared image of what constitutes effective teaching and staff taking join responsibility to all student’s achievement and well-bing.  Armed with this data, the leadership team prioritised these elements in their school improvement plan.  By focusing on enhancing communication between teachers and teams, and fostering a more inclusive school culture, the school aimed to improve structures for effective meetings and professional learning aimed at the improvement of teaching quality.   The specific statements from the survey provided clear, actionable insights, allowing the school to set measurable goals and track progress effectively.  This strategic approach not only clarified the school’s direction, but also engaged the entire staff in collective efforts to transform the school climate, ultimately leading to improved student performance and well-being. 
            </Text>
            <Text as="i">
              At <strong>school B</strong>, the completion of the survey revealed significant challenges related to shared understandings among staff, particularly concerning the clarity of goals, defined roles, and the effectiveness of meeting structures.  The findings indicated that these issues were not isolated, but were compounded by a detrimental distraction of tasks within the teams, leading to confusion and inefficiencies in collaboration efforts.  As a response, the school leadership team initiated a further assessment of the inner workings of the teams to identify specific areas for improvement. This assessment aimed to establish more functional structures and enhance support systems surrounding student assessment, ensuring that all team members are aligned in their objectives and roles, ultimately fostering a more cohesive and productive educational environment conducive to student success.
            </Text>
            <Heading as="h4" fontSize="2xl">References</Heading>
            <Text fontSize="md">
              Bryk, A.S., Sebring, P.B., Allensworth, E., Luppescu, S., & Easton, J.Q. (2010). Organising schools for Improvement: Lessons from Chicago. University of Chicago Press.
            </Text>
            <Text fontSize="md">
              DuFour, R., DuFour, R., Eaker, R., Many, T.W., Mattos, M., & Muhammad, A. (2024). Learning by doing: A handbook for professional learning communities, 4th Ed.  Solution Tree Press.
            </Text>
            <Text fontSize="md">
              Fullan, M.  & Quinn, J. (2016). Coherence: The right drivers in Action for schools, districts, and systems. Corwin Publishers.
            </Text>
            <Text fontSize="md">
              Hattie, J. (2023). Visible learning: The sequel: A synthesis of over 2,100 meta-analyses relating to achievement.  Rutledge publishers.
            </Text>
            <Text fontSize="md">
              Hayes, P., Kindberg, U., & Eliasson, C. (2024, August 25). Key factors for an effective ‘system-thinking’ school. School leadership and learning.  https://sllglobal.com/f/key-factors-for-an-effective-%E2%80%98system-thinking%E2%80%99-school
            </Text>
            <Text fontSize="md">
              Leithwood, K., Sun, J., & Schumacker, R. (2020). How School Leadership Influences Student Learning: A Test of “The Four Paths Model”. Educational Administration Quarterly, 56(4), 570-599.
            </Text>
            <Text fontSize="md">
              Marzano, R.J., Warrick, P., & Simms, J.A.(2014). A handbook for High Reliability Schools: The next step in school reform. Hawker Brownlow Education.
            </Text>
            <Text fontSize="md">
              Sharratt, L. (2019). Clarity: What matters most in learning, teaching and leading. Corwin Publishers.
            </Text>
            <Text fontSize="md">
              Snart, F. (2010). McKinsey & Company report: “How the world’s most improved school systems keep getting better”. Journal of  Educational Change 12, 457–462.
            </Text>
            <Text fontSize="md">
              Robinson, V. (2017). Reduce change to increase improvement.  Corwin Publishers.
            </Text>
            <Text fontSize="md">
              Timperley, H., Ell, F., Le Fevre, D., & Twyford, K. (2019).
            </Text>
          </VStack>
        </Container>
      </Box>
      <SlimFooter />
    </>
  );
};

export default ResourceArticle;