import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  IconButton,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  HStack,
  Center,
  Stack,
} from '@chakra-ui/react';
import { FiAlertTriangle } from 'react-icons/fi';
import axios from '../../axiosConfig';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SurveyLayout = ({ children }) => {
  const { endpoint } = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [headerContent, setHeaderContent] = useState(t('public_survey.defaultHeader'));
  const [surveyName, setSurveyName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [isSurveyAvailable, setIsSurveyAvailable] = useState(true); // Survey availability state
  const [availabilityMessage, setAvailabilityMessage] = useState('');

  const fetchSurveyDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/public_surveys/${endpoint}`);
      if (response.data) {
        const { name, organization, start, end } = response.data;

        setSurveyName(name || '');
        setOrganizationName(organization || '');

        // Validate date range
        const now = new Date();
        const startDate = new Date(start);
        const endDate = new Date(end);

        if (now < startDate) {
          setIsSurveyAvailable(false);
          setAvailabilityMessage(t('public_survey.unavailable.notStarted'));
        } else if (now > endDate) {
          setIsSurveyAvailable(false);
          setAvailabilityMessage(t('public_survey.unavailable.expired'));
        } else {
          setIsSurveyAvailable(true);
        }

        const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
        localStorage.setItem(
          'survey_session',
          JSON.stringify({
            ...surveySession,
            surveyName: name,
            organizationName: organization,
            endpoint,
          })
        );
      }
    } catch (error) {
      console.error('Error fetching survey details:', error);
      setIsSurveyAvailable(false);
      setAvailabilityMessage(t('public_survey.unavailable.error'));
    }
  }, [endpoint, t]);

  useEffect(() => {
    fetchSurveyDetails();
  }, [fetchSurveyDetails]);

  useEffect(() => {
    if (location.pathname.includes('/questions') || location.pathname.includes('/complete')) {
      setHeaderContent(`${organizationName} - ${surveyName}`);
    } else {
      setHeaderContent(surveyName || t('public_survey.defaultHeader'));
    }
  }, [location.pathname, organizationName, surveyName, t]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Flex direction="column" minH="100vh">
      <Box as="header" bg="teal.600" color="white" py={4} textAlign="center">
        <Text fontSize={["lg", "2xl"]} fontWeight="bold" maxW="90%" mx="auto">
          {headerContent}
        </Text>
      </Box>

      <VStack as="main" flex="1" p={6} mx="auto" maxW="4xl" spacing={6}>
        {!isSurveyAvailable ? (
          <Center>
            <Stack
              bg="yellow.100"
              p={6}
              borderRadius="md"
              border="1px solid"
              borderColor="yellow.300"
              shadow="md"
              spacing={4}
              textAlign="center"
              maxW="lg"
            >
              <Image
                src="/images/icons/warning.svg"
                alt="Warning Icon"
                boxSize={12}
                mx="auto"
              />
              <Text fontSize="lg" fontWeight="semibold" color="yellow.800">
                {t('public_survey.unavailable.header')}
              </Text>
              <Text fontSize="md" color="yellow.700">
                {availabilityMessage}
              </Text>
            </Stack>
          </Center>
        ) : (
          children
        )}
      </VStack>

      <Box as="footer" bg="orange.500" color="white" py={4} textAlign="center">
        <HStack spacing={4} justifyContent="center">
          <Text fontSize="sm">{t('public_survey.footerText')}</Text>
          <Popover placement="top">
            <PopoverTrigger>
              <IconButton
                aria-label="Select Language"
                icon={
                  <Image
                    src={`/images/country-flags/${i18n.language === 'en' ? 'gb.svg' : 'se.svg'}`}
                    alt={i18n.language === 'en' ? 'English' : 'Swedish'}
                    boxSize="26px"
                    borderRadius="full"
                    boxShadow="md"
                  />
                }
                size="lg"
                bg="transparent"
                borderRadius="full"
                _hover={{ bg: 'gray.100' }}
              />
            </PopoverTrigger>
            <PopoverContent w="auto" p="2">
              <PopoverBody>
                <Button
                  variant="ghost"
                  w="full"
                  justifyContent="flex-start"
                  onClick={() => changeLanguage('en')}
                  leftIcon={
                    <Image
                      src="/images/country-flags/gb.svg"
                      alt="English"
                      boxSize="20px"
                      borderRadius="full"
                    />
                  }
                >
                  English
                </Button>
                <Button
                  variant="ghost"
                  w="full"
                  justifyContent="flex-start"
                  onClick={() => changeLanguage('sv')}
                  leftIcon={
                    <Image
                      src="/images/country-flags/se.svg"
                      alt="Swedish"
                      boxSize="20px"
                      borderRadius="full"
                    />
                  }
                >
                  Swedish
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </Box>
    </Flex>
  );
};

export default SurveyLayout;