import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Cookies from 'js-cookie';
import { useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState({ theme: 'light', language: 'English' });
  const colorMode = useColorMode();
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookies.get('hkeAuthToken');
        if (!token) {
          setUser(null);
          setIsLoading(false);
          return;
        }

        const response = await axios.get('/users/me', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setUser(response.data);
        if (response.data.settings) {
          setSettings(response.data.settings);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (colorMode.setColorMode && settings.theme) {
      colorMode.setColorMode(settings.theme);
    }

    if (settings.language) {
      i18n.changeLanguage(settings.language);
    }
  }, [settings, colorMode, i18n]);

  // 🔹 Function to change password
  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await axios.patch('/users/me/change_password', {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPassword,
      });

      return response.status === 200;
    } catch (error) {
      console.error('Error changing password:', error);
      return false;
    }
  };

  // 🔹 Function to update user profile
  const updateUser = async (updatedData) => {
    try {
      const response = await axios.patch('/users/me/profile', updatedData);
      if (response.status === 200) {
        setUser(response.data.user); // Update the user state
        return true;
      }
    } catch (error) {
      console.error('Error updating user profile:', error);
      return false;
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, isLoading, settings, setSettings, changePassword, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};