import React from 'react';
import {
  Flex,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  IconButton,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import { FaUser, FaCogs, FaAsterisk, FaSignOutAlt } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';
import Cookies from 'js-cookie';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

// Function to create dynamic breadcrumb items based on the current path
const createBreadcrumbs = (location) => {
  const pathnames = location.pathname.split('/').filter((x) => x && x !== 'hub');
  return pathnames.map((value, index) => {
    const to = `/hub/${pathnames.slice(0, index + 1).join('/')}`;
    return { label: value.charAt(0).toUpperCase() + value.slice(1), href: to };
  });
};

const OrgNavbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const breadcrumbs = createBreadcrumbs(location);
  const { user } = useUser();
  const colorMode = useColorModeValue('light', 'dark');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');

  const handleLogout = () => {
    Cookies.remove('hkeAuthToken');
    window.location.href = '/login';
  };

  return (
    <Flex as="nav" align="center" justify="space-between" p="1rem" bg="white">
      <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/hub/dashboard">
            {t('navbar.dashboard')}
          </BreadcrumbLink>
        </BreadcrumbItem>
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbItem key={index}>
            <BreadcrumbLink as={Link} to={breadcrumb.href}>
              {breadcrumb.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>

      {/* User Dropdown */}
      {user && (
        <HStack spacing={4}>
          <Menu>
            <MenuButton as={IconButton} icon={<Icon as={FaUser} />} />
            <MenuList>
              {/* Display User Full Name and Email */}
              <Box px={4} py={2}>
                <Text fontWeight="bold">{user.first_name} {user.last_name}</Text>
                <Text fontSize="sm" color="gray.500">{user.email}</Text>
              </Box>
              <MenuDivider />

              <MenuItem as={Link} to="/hub/profile">
                <Icon as={FaUser} mr={2} /> {t('navbar.profile')}
              </MenuItem>
              <MenuItem as={Link} to="/hub/password">
                <Icon as={FaAsterisk} mr={2} /> Change Password
              </MenuItem>
              <MenuItem as={Link} to="/hub/settings">
                <Icon as={FaCogs} mr={2} /> {t('navbar.settings')}
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>
                <Icon as={FaSignOutAlt} mr={2} /> {t('navbar.logout')}
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      )}
    </Flex>
  );
};

export default OrgNavbar;